import { KeyValuePairs } from '@cloudscape-design/components';

import { useUserManagerContext } from 'pages/role-manager/RolesListPage';

const RoleDetails = () => {
    const { selectedItem } = useUserManagerContext();
    return (
      <KeyValuePairs 
          columns={2}
          items = {[
            {label: 'Description', value: selectedItem![0]?.description},
            {label: 'IDP Group', value: selectedItem![0]?.idpgroup},
            {label: 'Resource Type', value: selectedItem![0]?.resourcetype},
            {label: 'ID', value: selectedItem![0]?.id},
            {label: 'Resource', value: selectedItem![0]?.resource || '-'},
            {label: 'Sensor Groups', value: selectedItem![0]?.groupIds?.join(', ') || '-'}
          ]}
        />
    );
};

export default RoleDetails;
