import { useCollection } from "@cloudscape-design/collection-hooks";
import { Button, ButtonDropdown, Header, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
import { deviceManagerAPI } from "api";
import { EmptyState } from "components/empty-state/EmptyState";
import useFetch from "hooks/useFetch";
import { useEffect, useMemo, useState } from "react";
import { getMatchesCountText } from "utils";
import { COLUMN_DEFINITIONS } from "./table-config";
import DeleteModal from "components/delete-modal";
import SendDownlinkModal from "./SendDownlinkModal";
import useMutation from "hooks/useMutation";
import { Asset } from "types/custom";
import { usePageLayoutContext } from "components/common/layout";

type DeviceDownlink = {
  msgId: string;
  devEUI: string;
  port: number;
  confirmed: boolean;
  data: string;
};

const DownlinkQueue = ({ selectedDevice }:{ selectedDevice: Asset }) => {
  
  const [selectedDownlink, setSelectedDownlink] = useState<any[]>([]);
  const [isSendDownlinkModalOpen, setIsSendDownlinkModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { setNotification } = usePageLayoutContext();

  const { data, isLoading, refetch, isFetching, status } = useFetch<DeviceDownlink[]>({
    key: `device-downlink-queue-${selectedDevice?.name}`,
    url: `/deviceRegistry/downlink/${selectedDevice?.name}`,
    axiosInstance: deviceManagerAPI,
    enabled: !!selectedDevice?.name,
    placeholderData: [],
  });

  useEffect(() => {
    if (status === 'error') {
      setNotification([{ type: status, content: 'Failed to fetch downlink queue' }]);
    }
  }, [status]);

  const deviceDownlinks = useMemo(() => data, [data]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    deviceDownlinks || [],
    {
      filtering: {
        empty: (
          <EmptyState
            title="The queue is empty"
            subtitle={`Device ${selectedDevice?.name} has no downlinks in its queue.`}
            action={
              <Button onClick={() => setIsSendDownlinkModalOpen(true)}>
                Send a downlink
              </Button>
            }
          />
        ),
        noMatch: (
          <EmptyState
            title="No matches"
            subtitle="We can’t find a match."
            action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
          />
        ),
      },
      pagination: { pageSize: 10 },
      sorting: {},
    }
  );

  const { mutateAsync: deleteDownlinkQueue, isPending } = useMutation<{}>({
    url: `/deviceRegistry/downlink/${selectedDevice?.name}`,
    method: 'DELETE',
    api: deviceManagerAPI,
    notifications: [
      {type: 'success', content: 'Successfully cleared downlink queue'},
      {type: 'error'}
    ],
  });

  const onDeleteConfirm = async () => {
    if (!deviceDownlinks || deviceDownlinks.length === 0) return;

    await deleteDownlinkQueue({});

    setIsDeleteModalOpen(false);
    refetch();
  }

  return (
    <>
      <Table
        {...collectionProps}
        wrapLines={true}
        loading={isLoading}
        stripedRows={true}
        resizableColumns={true}
        onSelectionChange={({ detail }) => setSelectedDownlink(detail.selectedItems)}
        selectedItems={selectedDownlink}
        columnDefinitions={COLUMN_DEFINITIONS}
        items={items || []}
        loadingText="Loading downlinks"
        variant="embedded"
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: 'Next page',
              previousPageLabel: 'Previous page',
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find downlinks"
          />
        }
        header={
          <Header
            counter={`(${selectedDownlink.length}/${items.length})`}
            actions={
              <SpaceBetween size="xs" direction="horizontal">
                <Button iconName='refresh' onClick={() => refetch()} loading={isFetching} />
                <ButtonDropdown
                  onItemClick={({ detail }) => {
                    if (detail.id === "send-downlink") {
                      setIsSendDownlinkModalOpen(true);
                    }
                    if (detail.id === "delete-queue") {
                      setIsDeleteModalOpen(true);
                    }
                  }}
                  items={[
                    { text: "Send Downlink", id: "send-downlink" },
                    { text: "Delete Queue", id: "delete-queue", disabled: items.length === 0 },
                  ]}
                >
                  Actions
                </ButtonDropdown>
              </SpaceBetween>
            }
          >
            {selectedDevice?.friendlyName || selectedDevice?.friendlyName} Downlinks Queue
          </Header>
        }
      />

      { isDeleteModalOpen && 
        <DeleteModal
        visible={isDeleteModalOpen}
        onDiscard={() => setIsDeleteModalOpen(false)}
        onDelete={onDeleteConfirm}
        itemName={["Downlink"]}
        moduleName="Queue" 
        itemCount={data?.length}        
        loading={isPending}
        />
      }

      { isSendDownlinkModalOpen && 
        <SendDownlinkModal
          visible={isSendDownlinkModalOpen}
          refetchTableData={refetch}
          setIsVisible={setIsSendDownlinkModalOpen} 
          selectedDevice={selectedDevice} />
      }

    </>
  );
};

export default DownlinkQueue;