import { Alert, Spinner } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';

import ListItemComp from 'components/incident-manager/ListItem';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

const GroupsList = () => {
    const { allGroups, setSelectedGroup, groupsLoading } =
        useNotificationManagerContext();

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (allGroups) {
            setSelectedGroup(allGroups[0]);
            setSelectedIndex(0);
        }
    }, [allGroups]);

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
    };

    return (
      <>
        {groupsLoading && <Spinner />}
        {!groupsLoading && !allGroups?.length && (
          <Alert>No notification groups found</Alert>
            )}

        <ListItemComp
                allItems={allGroups}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                setSelectedItem={setSelectedGroup}
                module='groups'
            />
      </>
    );
};

export default GroupsList;
