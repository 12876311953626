import {
  Modal,
  Box,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Form,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_DEVICE } from 'constants/urls';
import useMutation from 'hooks/useMutation';
import { Asset } from 'types/custom';

const UpdateDeviceModal = ({
  selectedDevice,
  visible,
  onDiscard,
  refetch,
}: {
  selectedDevice: any[];
  visible: boolean;
  onDiscard: () => void;
  refetch: () => void;
}) => {
  const [description, setDescription] = useState(
    selectedDevice[0].description
  );
  const [friendlyName, setFriendlyName] = useState(
    selectedDevice[0].friendlyName
  );

  const {
    mutateAsync: updateDevice,
    status: updateDeviceStatus,
    error: updateDeviceError,
    isPending: updateDeviceLoading,
  } = useMutation<Asset, {
    description: string;
    friendlyName: string;
  }>({
    api: deviceManagerAPI,
    method: 'PATCH',
    url: `${API_URL_PATH_DM_DEVICE}/${selectedDevice[0].name}/attributes`,
  });

  useEffect(() => {
    if (updateDeviceStatus === 'success') {
      refetch();
      onDiscard();
    }
  }, [updateDeviceStatus]);

  return (
    <Modal
      onDismiss={onDiscard}
      visible={visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={onDiscard}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={async () => await updateDevice({
                description,
                friendlyName,
              })}
              loading={updateDeviceLoading}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
      header='Update Device'
    >
      <Form errorText={updateDeviceError?.message}>
        <SpaceBetween direction='vertical' size='l'>
          <FormField
            label={
              <span>
                Description <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              value={description}
              onChange={(event) =>
                setDescription(event.detail.value)
              }
            />
          </FormField>
          <FormField label='Friendly Name'>
            <Input
              value={friendlyName}
              onChange={(event) =>
                setFriendlyName(event.detail.value)
              }
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Modal>
  );
};

export default UpdateDeviceModal;
