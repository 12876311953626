import { FormField, Multiselect, Select } from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useEffect, useState } from 'react';

import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_DEVICE_LIST } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { Asset } from 'types/custom';

const DeviceSelect = ({
    selectedAssets,
    devicesToUse,
    setSelectedAssets,
    disabled,
    isMulti = true,
}: {
    selectedAssets: readonly OptionDefinition[];
    devicesToUse?: readonly Asset[];
    setSelectedAssets: (state: readonly OptionDefinition[]) => void;
    disabled: boolean;
    isMulti?: boolean;
}) => {
    const [allAssets, setAllAssets] = useState<OptionDefinition[]>(devicesToUse ? devicesToUse.map((device: Asset) => ({
        label: device.friendlyName,
        description: device.description,
        labelTag: device.name,
        value: device.name,
    })) : []);

    const {
        data: devicesList,
        error: devicesError,
        status: devicesStatus,
        isLoading: devicesLoading,
    } = useFetch<Asset[]>({
        axiosInstance: deviceManagerAPI,
        url: API_URL_PATH_DM_DEVICE_LIST,
        key: 'devices',
        enabled: !devicesToUse,
    });

    useEffect(() => {
        if (devicesList && devicesStatus === 'success' && !devicesToUse) {
            setAllAssets(devicesList.map((device: Asset) => ({
                label: device.friendlyName,
                description: device.description,
                labelTag: device.name,
                value: device.name,
            })));
        }
    }, [devicesList, devicesStatus, devicesToUse]);

    return (
      <FormField
            label={
              <>
                {!devicesToUse && <span>Devices - optional</span>}
                {devicesToUse && <span>Device</span>}
              </>
            }
        >
        {isMulti ? <Multiselect
                selectedOptions={selectedAssets}
                onChange={({ detail }) =>
                    setSelectedAssets(detail.selectedOptions)
                }
                disabled={disabled}
                filteringType='auto'
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={allAssets}
                placeholder='Choose options'
                selectedAriaLabel='Selected'
                loadingText='Loading assets'
                statusType={devicesLoading ? 'loading' : 'finished'}
                errorText={String(devicesError)}
                empty='No options'
            /> :
        <Select
                    selectedOption={selectedAssets[0]}
                    onChange={({ detail }) => {
                        setSelectedAssets([detail.selectedOption]);
                    }}
                    disabled={disabled}
                    filteringType='auto'
                    options={allAssets}
                    placeholder='Choose an option'
                    selectedAriaLabel='Selected'
                    loadingText='Loading assets'
                    statusType={devicesLoading ? 'loading' : 'finished'}
                    errorText={String(devicesError)}
                    empty='No options' />
            }
      </FormField>
    );
};

export default DeviceSelect;
