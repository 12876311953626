import { ContentLayout, Grid, Header } from '@cloudscape-design/components';

import AlertRulesManagerProvider from 'providers/AlertRulesManagerProvider';
import colors from 'theme/colors';

import { Outlet } from 'react-router-dom';

const AlertRulesManager = () => {
    return (
      <AlertRulesManagerProvider>
        <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<Header variant="h1">Alert Rules Manager</Header>}>
          <Grid gridDefinition={[{ colspan: 12 }]}>
            <Outlet />
          </Grid>
        </ContentLayout>
      </AlertRulesManagerProvider>
    );
};

export default AlertRulesManager;
