import { StatusIndicator, StatusIndicatorProps } from "@cloudscape-design/components";

import { StateAlertCode } from "types/custom";

const AlertStatusBadge = ({code, noColor = false}: {code: StateAlertCode, noColor?: boolean}) => {
	let indType: [StatusIndicatorProps.Type, string] = ['info', 'Unknown'];
    switch (code) {
		case StateAlertCode.LL:
			indType = ['error', 'Low-Low'];
			break;
		case StateAlertCode.L:
			indType = ['error', 'Low'];
			break;
		case StateAlertCode.H:
			indType = ['error', 'High'];
			break;
		case StateAlertCode.HH:
			indType = ['error', 'High-High'];
			break;
		case StateAlertCode.LL_PENDING:
			indType = ['warning', 'Low-Low Pending'];
			break;
		case StateAlertCode.L_PENDING:
			indType = ['warning', 'Low Pending'];
			break;
		case StateAlertCode.H_PENDING:
			indType = ['warning', 'High Pending'];
			break;
		case StateAlertCode.HH_PENDING:
			indType = ['warning', 'High-High Pending'];
			break;
		case StateAlertCode.N:
			indType = ['success', 'Normal'];
			break;
		case StateAlertCode.N_PENDING:
			indType = ['warning', 'Normal Pending'];
			break;
		case StateAlertCode.U:
		default:
			indType = ["info", "Unknown"];
	}
	return (<StatusIndicator type={indType[0]} colorOverride={noColor ? 'grey' : undefined}>{indType[1]}</StatusIndicator>);
}

export default AlertStatusBadge;