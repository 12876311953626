import { Button, StatusIndicator, Box, Grid } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import useAuth from 'hooks/useAuth';
import Spinner from 'components/spinner/Spinner';

import colors from '../../../theme/colors';

const LoginPage = () => {
    const [authError, setAuthError] = useState<any>('');

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || '/';

    const [searchParams] = useSearchParams();
    const authCode = searchParams.get('code');

    const { user, token } = useAuth();

    const handleLogin = () => {
        Auth.federatedSignIn({
            customProvider:
                window['runtimeConfig'].cognitoSignInProvider || '',
            customState: encodeURIComponent(from),
        }).catch((error) => {
            setAuthError(error);
        });
    };

    useEffect(() => {
        if (user || token) {
            navigate(from, { replace: true });
        }
    }, [user, token]);

    if (user || token || authCode ) return <Spinner />;

    return (
      <Grid disableGutters={true}
        gridDefinition={[
            { colspan: { xs: 6, default: 0 } },
            { colspan: { xs: 6, default: 12 } },
        ]}>
        <div className='align-center' style={{ backgroundColor: colors.accentGreen2 }}>
          <img src='/logo-white.svg' alt='logo' style={{ maxHeight: '150px', maxWidth:'80%' }}/>
        </div>
        <div className='align-center'>
          <Box variant='h2' textAlign='center'>
            Sensor Visualization, Alerting & Notification
          </Box>
          <Box variant='strong' margin={{ bottom: 'xxxl' }} textAlign='center'>
            SVAN {window['runtimeConfig'].envTitle}
          </Box>
          <Button onClick={handleLogin} iconName='lock-private'>
            <span style={{ fontSize: '20px' }}>Log in with SSO</span>
          </Button>
          {authError && (<StatusIndicator type='error'>{authError}</StatusIndicator>)}
        </div>
      </Grid>
    );
};

export default LoginPage;
