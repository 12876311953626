import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

import { Asset, HierarchyNode, MinimalNode } from "types/custom";

export const makeNodeFromDevice = (device: Asset): HierarchyNode => ({
  id: device.name,
  name: device.friendlyName || device.name || '',
  assetList: [],
  children: [],
  isDevice: true,
});

export const makeNodeFromOption = (device: OptionDefinition): HierarchyNode => ({
  id: device.value!,
  name: device.label,
  assetList: [],
  children: [],
  isDevice: true,
});

export const findSourceNodeAndParentInTree = (
  node: HierarchyNode, 
  tree: HierarchyNode
): { 
  node: HierarchyNode | null; 
  parent: HierarchyNode | null; 
} => {  if (tree.id === node.id) {
  return { node: tree, parent: null };
}

if (tree.children) {
  for (let i = 0; i < tree.children.length; i++) {
    const found = findSourceNodeAndParentInTree(node, tree.children[i]);
    if (found.node) {
      return { node: found.node, parent: found.parent ? found.parent : tree };
    }
  }
}

return { node: null, parent: null };
};

export const isInHierarchy = (node: HierarchyNode, tree: HierarchyNode): boolean => {
  if (tree.id === node.id) {
    return true;
  }

  if (!tree.children) {
    return false;
  }

  return tree.children.some((child) => isInHierarchy(node, child));
};

export const deleteNode = (node: HierarchyNode, hierarchyTree: HierarchyNode): boolean => {
  let found = false;

  if (hierarchyTree.children) {
    for (let i = 0; i < hierarchyTree.children.length; i++) {
      if (hierarchyTree.children[i].id === node.id) {
        hierarchyTree.children = hierarchyTree.children.filter((child) => child.id !== node.id);
        found = true;
        break;
      } else {
        deleteNode(node, hierarchyTree.children[i]);
      }
    }
  }

  return found;
};

export const toMinimalNode = (node: HierarchyNode): MinimalNode => ({
  tagId: node.id,
  children: !node.isDevice ? node.children?.filter(x => !x.isDevice)?.map(toMinimalNode) : undefined,
});

export const naturalSortString = (a: string, b: string) =>
  (a || '').localeCompare(b || '', 'en', { numeric: true });

export const naturalSortAsset = (a: Asset, b: Asset) =>
  (a?.friendlyName || a?.name || '').localeCompare(b?.friendlyName || b?.name || '', 'en', { numeric: true });

export const naturalSortNode = (a: HierarchyNode, b: HierarchyNode) =>
  (+(a?.isDevice || false) - +(b?.isDevice || false)) || (a.name || '').localeCompare(b.name || '', 'en', { numeric: true });

export const naturalSortNodeId = (a: HierarchyNode, b: HierarchyNode) =>
  (a?.id || '').localeCompare(b?.id || '', 'en', { numeric: true });