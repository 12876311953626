import { Grid, Container, Header, Button } from '@cloudscape-design/components';

import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

import PlansList from './PlansList';
import PlansView from './PlansView';

const EscalationPlans = () => {
  const { plansRefetch, plansFetching } = useNotificationManagerContext();
  return (
    <Grid
      gridDefinition={[
        { colspan: { m: 4, s: 3, xs: 12, xxs: 12 } },
        { colspan: { m: 8, s: 9, xs: 12, xxs: 12 } },
      ]}
    >
      <Container className='custom-height-container custom-scroll-container'
        header={<Header variant='h3' actions={
          <Button iconName='refresh' onClick={plansRefetch} loading={plansFetching} />
        }>Escalation Plans</Header>}>
        <PlansList />
      </Container>
      <Container className='custom-height-container custom-scroll-container'>
        <PlansView />
      </Container>
    </Grid>
  );
};

export default EscalationPlans;
