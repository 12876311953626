import { Tabs } from '@cloudscape-design/components';

import DeviceGroupDetails from '../DeviceGroupDetails';

const DeviceGroupTabs = () => {
    return (
      <Tabs
            tabs={[
                {
                    label: 'Details',
                    id: 'details',
                    content: <DeviceGroupDetails />,
                },
            ]}
            variant='container'
        />
    );
};

export default DeviceGroupTabs;
