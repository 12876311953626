import { Outlet } from 'react-router-dom';
import { ContentLayout, Grid, Header } from '@cloudscape-design/components';

import colors from '../../theme/colors';

const DeviceManager = () => {
    return (
      <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<Header variant="h1">Device Manager</Header>}>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          <Outlet />
        </Grid>
      </ContentLayout>
    );
};

export default DeviceManager;
