import { DndProvider } from "react-dnd";
import { SimpleTreeView } from "@mui/x-tree-view";
import { useEffect, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Alert, Modal, SelectProps, Spinner } from "@cloudscape-design/components";

import { getAuthConfig } from "utils";
import { deviceManagerAPI } from "api";
import DeviceTabs from "components/device-manager/DeviceTabs";
import { usePageLayoutContext } from "components/common/layout";
import { useDashboardContext } from "providers/DashboardProvider";
import useFetch from "hooks/useFetch";
import useMutation from "hooks/useMutation";
import { Asset, DeviceState, Hierarchy, HierarchyNode, MinimalNode, Tag } from "types/custom";
import { API_URL_PATH_HIERARCHY, API_URL_PATH_TAG, API_URL_PATH_DM_DEVICE_LIST, API_URL_PATH_TAG_BY_HIERARCHY } from "constants/urls";
import DeleteModal from "components/delete-modal";

import { deleteNode, toMinimalNode, findSourceNodeAndParentInTree, makeNodeFromDevice, isInHierarchy, naturalSortString, naturalSortNode, naturalSortAsset } from "./utils";
import { AddDevicesModal } from "./AddDevicesModal";
import HierarchyItem from "./HierarchyItem";

const HierarchyTree = () => {
  const [hierarchyTree, setHierarchyTree] = useState<HierarchyNode>();
  const [enabledDevices, setEnabledDevices] = useState<SelectProps.Options | undefined>([]);
  const [allIds, setAllIds] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [expandWith, setExpandWith] = useState<string | null>(null);
  const [devicesPresentInTree, setDevicesPresentInTree] = useState<string[]>([]);
  const [tagForAddModal, setTagForAddModal] = useState<HierarchyNode | null>(null);
  const [deviceDetailsForModal, setDeviceDetailsForModal] = useState<string | null>(null);
  const [isDeleteTagModalVisible, setIsDeleteTagModalVisible] = useState(false);
  const deleteNodeRef = useRef<HierarchyNode | null>(null);
  const [isDeletingTag, setIsDeletingTag] = useState(false);
  const [isDetachDeviceModalVisible, setIsDetachDeviceModalVisible] = useState(false);
  const detachDeviceRef = useRef<HierarchyNode | null>(null);
  const [isDetachingDevice, setIsDetachingDevice] = useState(false);

  const { setNotification } = usePageLayoutContext();

  const {
    setSelectedNode,
    setChartDevices,
    chartDevices,
    setRolesSelectDisabled,
    selectedRole,
    expandAll,
    setExpandAll
  } = useDashboardContext();

  const isUpdating = useRef(false);

  const {
    data: hierarchyResponse,
    isLoading: isHierarchyLoading,
    error: hierarchyError,
  } = useFetch<Hierarchy>({
    key: `hierarchy-tree-${selectedRole}`,
    axiosInstance: deviceManagerAPI,
    url: `${API_URL_PATH_HIERARCHY}/${selectedRole || 'forUser'}`,
  });

  useEffect(() => {
    setHierarchyTree(undefined);
    setEnabledDevices([]);
    setAllIds([]);
    setDevicesPresentInTree([]);
  }, [selectedRole]);

  const {
    mutateAsync: fetchTagById,
    isPending: isTagLoading,
  } = useMutation<Tag>(
      {
        api: deviceManagerAPI,
        method: 'GET',
        url: API_URL_PATH_TAG,
        notifications: [
          { type: 'error', content: 'Error fetching tag data' }
        ],
      }
    );

  const {
    mutateAsync: allTagsForHierarchy,
    isPending: isAllTagsLoading,
  } = useMutation<{ items: Tag[] }>({
      api: deviceManagerAPI,
      method: 'GET',
      url: API_URL_PATH_TAG_BY_HIERARCHY,
      notifications: [{
        type: 'error', content: 'Error fetching all tags for one level hierarchy'
      }],
    });

  const {
    data: devicesResponse,
    error: devicesError,
    isLoading: isDevicesLoading,
  } = useFetch<Asset[]>({
      axiosInstance: deviceManagerAPI,
      url: API_URL_PATH_DM_DEVICE_LIST,
      key: 'devices',
    });

  const { mutate: renameTag } = useMutation<Tag, {
    name: string;
  }>({
    url: API_URL_PATH_TAG,
    method: 'PATCH',
    api: deviceManagerAPI,
    notifications: [{ type: 'error' }]
  });

  useEffect(() => {
    setRolesSelectDisabled(isHierarchyLoading || isTagLoading || isAllTagsLoading || isDevicesLoading);
  }, [isHierarchyLoading, isTagLoading, isAllTagsLoading, isDevicesLoading]);

  const fetchTag = async (node: HierarchyNode) => {
    if (!allIds.includes(node.id)) setAllIds(allIds => !allIds.includes(node.id) ? [...allIds, node.id] : allIds);
    const tag = await fetchTagById({ urlPath: node.id });
    if (!tag || tag.status !== 200) return;
    node.name = tag.data.name;
    node.assetList = tag.data.assetList;
    return node;
  };

  useEffect(() => {
    (async () => {
      if (!isHierarchyLoading && hierarchyResponse && devicesResponse) {
        const allTags = await allTagsForHierarchy({ urlPath: hierarchyResponse.id });
        if (!allTags || allTags.status !== 200) return;

        const nodes = hierarchyResponse.hierarchyTreeData.map(minimalNode => toHierarchyNode(allTags.data.items, minimalNode))
          .filter(x => x !== undefined) as HierarchyNode[];

        setExpanded([]);
        // Not clearing this while reloading the hierarchy will cause expanded tags without devices children, 
        // as they're added when they're clicked

        if (nodes?.[0]) {
          setHierarchyTree(nodes[0]); // Use only the first one, a tree with multiple roots is not supported
          handleNodeClick(nodes[0]); // Automatically render and expand first one
        } else {
          setHierarchyTree(undefined);
          setNotification([{
            type: 'error',
            content: 'Hierarchy tree is empty and does not contain a root node. Please recreate from the Role Manager.',
          }]);
        }
      }
    })();
  }, [hierarchyResponse, devicesResponse]);

  useEffect(() => {
    if (expandAll) {
      setExpanded(allIds);
      setExpandAll(false);
    }
  }, [expandAll]);

  const toHierarchyNode = (tags: Tag[], minimalNode: MinimalNode): HierarchyNode => {
    const tag = tags.find(tag => tag.id === minimalNode.tagId);

    setDevicesPresentInTree((prev: string[]) => [...prev, ...(tag?.assetList || [])]);
    setAllIds(allIds => !allIds.includes(minimalNode.tagId) ? [...allIds, minimalNode.tagId] : allIds);

    return {
      id: minimalNode.tagId,
      name: tag?.name || undefined,
      assetList: tag?.assetList?.sort(naturalSortString) || [],
      children: (minimalNode.children || []).map(child => toHierarchyNode(tags, child)).sort(naturalSortNode),
      isDevice: false,
    } as HierarchyNode;
  };

  const handleNodeClick = async (node: HierarchyNode) => {
    setSelectedNode(node);
    if (!node.isRendered) {
      // TODO: Device data should be dynamically loaded, not everything fetched at once
      // (this wouldn't be efficient if there're thousands of devices)
      // Current approach is flexible enough to allow this change in the future (in a way similar to the fetchTag method)
      node.isRendered = true;
      // Obtains devices from the asset list
      const devices = devicesResponse?.filter((x) => node?.assetList?.includes(x.name)).map(makeNodeFromDevice) || [];
      if (!expanded.includes(node.id) && !node.isDevice) setExpandWith(node.id);
      const tagsToFetch = node.children?.filter((child) => !child.children?.length && !child.isDevice) || [];
      // Show the data that's already fetched: tags with asset list (preloaded) and devices.
      // Filters tags that could have been added because of reordering
      node.children = [...node.children.filter(child => !child.isDevice), ...devices].sort(naturalSortNode);
      // Fetch the missing tags without blocking the rendering
      Promise.all(tagsToFetch.map((child) => fetchTag(child))).then((newTags) => {
        node.children = node.children.map((child) => newTags.find((tag) => tag && tag.id === child.id) || child);
      });
    }
  };

  useEffect(() => {
    // This is used to ensure expanded will always have the latest available value
    if (expandWith) {
      setExpanded([...expanded, expandWith]);
      setExpandWith(null);
    }
  }, [expandWith]);

  const handleNodeReorder = async (source: HierarchyNode, destination: HierarchyNode) => {
    if (!hierarchyTree || isUpdating.current || source.id === destination.id || isDevicesLoading) return;

    if (destination?.isDevice) {
      setNotification([{
        type: 'error',
        content: 'Items cannot be reordered inside other devices',
      }]);
      return;
    }

    if (isInHierarchy(destination, source)) {
      setNotification([{
        type: 'error',
        content: 'Items cannot be reordered inside one its children',
      }]);
      return;
    }

    const sourceParent = findSourceNodeAndParentInTree(source, hierarchyTree).parent;
    if (!sourceParent || destination.id === sourceParent.id) {
      return;
    }

    isUpdating.current = true;

    // Detaches from the source parent and attaches to the destination
    sourceParent.children = sourceParent?.children?.filter((child) => child.id !== source.id) || [];
    destination.children?.push(source);
    // Sorts by devices first, then by name using natural order
    destination.children.sort(naturalSortNode);

    // If it's a device, also update the asset list
    if (source.isDevice) {
      sourceParent.assetList = sourceParent?.assetList?.filter((child) => child !== source.id) || [];
      destination.assetList?.push(source.id);
    }

    setHierarchyTree({ ...hierarchyTree });
    if (!expanded.includes(destination.id) && destination.isRendered) setExpandWith(destination.id);

    try {
      if (source.isDevice) {
        // Removes deleted or orphaned assets from the asset list
        // TODO: This should be done on backend side
        const sourceAssetList = sourceParent?.assetList?.filter((name: string) =>
          devicesResponse?.find((asset) => asset.name === name) && name !== source.id) || [];
        const detachDevice = deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${sourceParent.id}`, {
          assetList: sourceAssetList,
          runDuplicateAssetValidation: false,
        }, await getAuthConfig());

        const destinationAssetList = [...(destination?.assetList?.filter((name: string) =>
          devicesResponse?.find((asset) => asset.name === name)) || []), source.id];
        const addDevice = deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${destination.id}`, {
          assetList: destinationAssetList,
          runDuplicateAssetValidation: false,
        }, await getAuthConfig());

        await Promise.all([detachDevice, addDevice]);
      }

      await handleSaveHierarchy();
      isUpdating.current = false;

    } catch (error: any) {
      setNotification([{
        type: 'error',
        content: `Unexpected error: ${error.message}. Please reload before attempting to do other updates.`,
      }]);
    }

  };

  const handleNodeDelete = async () => {
    const nodeToDelete = deleteNodeRef.current;
    if (!hierarchyTree || isUpdating.current || !nodeToDelete) return;
    isUpdating.current = true;
    setIsDeletingTag(true);

    if (nodeToDelete.assetList?.length) {
      setDevicesPresentInTree((prev: string[]) => prev.filter((asset: string) => !nodeToDelete.assetList?.includes(asset)));
    }

    deleteNode(nodeToDelete, hierarchyTree);
    await handleSaveHierarchy();
    setHierarchyTree({ ...hierarchyTree });
    isUpdating.current = false;
    setIsDeleteTagModalVisible(false);
    setIsDeletingTag(false);
  };

  const handleNodeAdded = async (parent: HierarchyNode) => {
    if (!hierarchyTree) return;
    await handleSaveHierarchy();
    setAllIds(allIds => !allIds.includes(parent.id) ? [...allIds, parent.id] : allIds);
    setExpandWith(parent.id);
    setHierarchyTree({ ...hierarchyTree });
  };

  const handleSaveHierarchy = async () => {
    if (!hierarchyResponse || !hierarchyTree) {
      setNotification([
        {
          type: 'error',
          content: 'Hierarchy is not loaded',
        },
      ]);
      return;
    }
    await deviceManagerAPI.patch(`${API_URL_PATH_HIERARCHY}/${hierarchyResponse?.id}`, {
      hierarchyTreeData: [toMinimalNode(hierarchyTree)],
    }, await getAuthConfig());
  };

  const handleTagRename = async (node: HierarchyNode) => {
    if (!hierarchyTree || isDevicesLoading) return;
    renameTag({
      name: node.name || '',
      urlPath: node.id
    });
    const { parent } = findSourceNodeAndParentInTree(node, hierarchyTree);
    if (!parent) return;
    parent.children = parent.children.sort(naturalSortNode);
    setHierarchyTree({ ...hierarchyTree });
  };

  const handleDeviceDetach = async () => {
    const deviceToDetach = detachDeviceRef.current;
    if (!hierarchyTree || isDevicesLoading || !deviceToDetach) return;
    setIsDetachingDevice(true);
    const { parent: tagContainingDevice } = findSourceNodeAndParentInTree(deviceToDetach, hierarchyTree);
    const tag = await fetchTagById({ urlPath: tagContainingDevice?.id });
    if (!tag || tag.status !== 200) return;
    const filteredAssetList = (tag.data as Tag)?.assetList?.filter((name) =>
      devicesResponse?.find((asset) => asset.name === name) && name !== deviceToDetach.id) || [];
    setDevicesPresentInTree((prev: string[]) => prev.filter((asset: string) => asset !== deviceToDetach.id));

    const authConfig = await getAuthConfig();
    await deviceManagerAPI.patch(`${API_URL_PATH_TAG}/${tagContainingDevice?.id}`, {
      assetList: filteredAssetList,
      runDuplicateAssetValidation: false,
    }, authConfig);

    await handleSaveHierarchy();
    deleteNode(deviceToDetach, hierarchyTree);
    setChartDevices(chartDevices.filter((x) => x.name !== deviceToDetach.name));
    setHierarchyTree({ ...hierarchyTree });
    setIsDetachingDevice(false);
    setIsDetachDeviceModalVisible(false);
  }

  useEffect(() => {
    if (devicesResponse?.length && !devicesError) {
      setEnabledDevices(devicesResponse
        .filter((device) => device.deviceState === DeviceState.inService)
        .sort(naturalSortAsset)
        .map((device) => ({
          label: device.friendlyName || device.name || '',
          labelTag: device.name,
          value: device.name,
          description: device.description,
          disabled: devicesPresentInTree.includes(device.name),
        }))
      );
    }

  }, [devicesPresentInTree, devicesResponse, devicesError]);

  if (hierarchyError && !isHierarchyLoading) {
    return (
      <Alert type="error" header="No data available">
        {hierarchyError.message || 'Error fetching hierarchy data'}
      </Alert>
    );
  }

  if (isHierarchyLoading || !hierarchyTree || isDevicesLoading) return <Spinner size="big" />;

  const deviceForDetailsModal = devicesResponse?.find(device => device.name === deviceDetailsForModal);

  return (<>
    {tagForAddModal && <Modal
      onDismiss={() => setTagForAddModal(null)}
      visible={tagForAddModal !== null}
      header="Add Device">
      <AddDevicesModal
        node={tagForAddModal}
        onNodeAdded={handleNodeAdded}
        dismissModal={() => setTagForAddModal(null)}
        allDevices={devicesResponse || []}
        devicesPresentInTree={devicesPresentInTree}
        setDevicesPresentInTree={setDevicesPresentInTree}
        enabledDevices={enabledDevices ?? []} />
    </Modal>}
    {deviceForDetailsModal && <Modal
      onDismiss={() => setDeviceDetailsForModal(null)}
      visible={deviceDetailsForModal !== null}
      size="large"
      header={deviceForDetailsModal?.friendlyName || deviceDetailsForModal}>
      <DeviceTabs selectedDevices={[deviceForDetailsModal]} isInModal={true} />
    </Modal>}
    {isDeleteTagModalVisible && <DeleteModal
      visible={isDeleteTagModalVisible}
      onDelete={() => handleNodeDelete()}
      onDiscard={() => setIsDeleteTagModalVisible(false)}
      itemCount=""
      moduleName="tag"
      itemName={undefined}
      loading={isDeletingTag}
    />}
    {isDetachDeviceModalVisible && <DeleteModal
      visible={isDetachDeviceModalVisible}
      onDelete={() => handleDeviceDetach()}
      onDiscard={() => setIsDetachDeviceModalVisible(false)}
      itemCount=""
      moduleName="device"
      itemName={undefined}
      loading={isDetachingDevice}
      isNotPermanent
      action="Detach"
    />}
    <DndProvider backend={HTML5Backend}>
      <SimpleTreeView
        onExpandedItemsChange={(_event, nodeIds) => setExpanded(nodeIds)}
        expandedItems={expanded}
        itemChildrenIndentation={8}
      >
        <HierarchyItem
          node={hierarchyTree!}
          isRootTag={true}
          hierarchyId={hierarchyResponse!.id}
          isUpdating={isUpdating}
          onNodeDropped={handleNodeReorder}
          onNodeClick={handleNodeClick}
          onNodeDelete={(node) => {
            setIsDeleteTagModalVisible(true);
            deleteNodeRef.current = node;
          }}
          onDeviceDetach={(node) => {
            setIsDetachDeviceModalVisible(true);
            detachDeviceRef.current = node;
          }}
          onTagRename={handleTagRename}
          onNodeAdded={handleNodeAdded}
          setDeviceForDetailsModal={setDeviceDetailsForModal}
          setTagForAddModal={setTagForAddModal}
        />
      </SimpleTreeView>
    </DndProvider>
  </>);
};

export default HierarchyTree;