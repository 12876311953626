import { StatusIndicator, StatusIndicatorProps } from "@cloudscape-design/components";

import { DeviceState } from "types/custom";
import { toTitleCase } from "utils";

const stateToTypeMap: Record<DeviceState, StatusIndicatorProps.Type> = {
	[DeviceState.inService]: 'success',
	[DeviceState.disabled]: 'warning',
	[DeviceState.deleted]: 'stopped',
};

const DeviceStateBadge = ({state}: {state: DeviceState}) => {
	return (<StatusIndicator type={stateToTypeMap[state] || 'info'}>
  {state === DeviceState.inService ? 'In Service' : toTitleCase(state)}
	</StatusIndicator>);
}

export default DeviceStateBadge;
