import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';
import ContactPhoneSharpIcon from '@mui/icons-material/ContactPhoneSharp';
import NotificationsActiveSharpIcon from '@mui/icons-material/NotificationsActiveSharp';
import { useState } from 'react';

type ListItemCompProps = {
    allItems: any;
    selectedIndex: number;
    handleListItemClick: (i: number) => void;
    setSelectedItem: any;
    module: string;
    disableContact?: (event: React.ChangeEvent<HTMLInputElement>, item: any) => void;
    isLoading?: boolean;
    removeContact?: (item: any) => void;
}

const ListItemComp = ({
    allItems,
    selectedIndex,
    handleListItemClick,
    setSelectedItem,
    module,
    disableContact,
    isLoading,
    removeContact,
}: ListItemCompProps) => {
    const [hoveredIndex, setHoveredIndex] = useState<number>();

    const handleClick = (item: any, index: number) => {
        handleListItemClick(index);
        setSelectedItem(item);
    };

    return (
      <List
            component='nav'
            aria-label='contacts'
        >
        {allItems &&
                allItems.length > 0 &&
                allItems.map((item: any, i: number) => (
                  <ListItem disablePadding key={item.id}>
                    <ListItemButton
                            selected={selectedIndex === i}
                            onClick={() => {
                                handleClick(item, i);
                            }}
                            alignItems='flex-start'
                        >
                      <ListItemIcon>
                        {module === 'contacts' ? (
                          <ContactPhoneSharpIcon />
                                ) : module === 'groups' ? (
                                  <NotificationsActiveSharpIcon />
                                ) : (
                                  <TextSnippetSharpIcon />
                                )}
                      </ListItemIcon>
                      <ListItemText
                                secondary={item.alias || item.description || ' '}
                                primary={
                                    module === 'contacts'
                                        ? `${item.firstName} ${item.lastName}`
                                        : item.name
                                }
                            />
                      {module === 'contacts' && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                                        defaultChecked={item.active ? true : false}
                                        disabled={isLoading}
                                        onChange={(event) => disableContact?.(event, item)}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    />
                          <RemoveCircleIcon
                                        color={i === hoveredIndex ? 'error' : 'disabled'}
                                        fontSize="medium"
                                        onMouseEnter={() => setHoveredIndex(i)}
                                        onMouseLeave={() => setHoveredIndex(undefined)}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            removeContact?.(item);
                                        }}
                                    />
                        </div>
                            )}
                    </ListItemButton>
                  </ListItem>
                ))}
      </List>
    );
};

export default ListItemComp;
