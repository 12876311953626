import { SpaceBetween, Badge, KeyValuePairs } from '@cloudscape-design/components';

import { useDeviceGroupsContext } from 'pages/device-group-manager/DeviceGroupListPage';

const DeviceGroupDetails = () => {
    const { selectedGroup } = useDeviceGroupsContext();
    return (
      <KeyValuePairs
        columns={3}
            items={[
                { label: 'Friendly Name', value: selectedGroup![0]?.friendlyName || '-' },
                { label: 'Description', value: selectedGroup![0]?.description || '-' },
                { label: 'Updated at', value: new Date(selectedGroup![0]?.updatedAt).toLocaleString() || '-' },
                { label: 'Roles', value: (
                  <SpaceBetween direction='horizontal' size='xs'>
                    {selectedGroup![0]?.userRoleIds?.map((roleId) => (
                      <Badge key={roleId}>{roleId}</Badge>
                    ))}
                    {selectedGroup![0]?.userRoleIds?.length === 0 && '-'}
                  </SpaceBetween>
                )},
                { label: 'Devices', value: (
                  <SpaceBetween direction='horizontal' size='xs'>
                    {selectedGroup![0]?.assets?.map((asset) => (
                      <Badge key={asset.assetId}>{asset.assetId}</Badge>
                        ))}
                    {selectedGroup![0]?.assets?.length === 0 && <span key="no-assets">-</span>}
                  </SpaceBetween>)
                },
                { label: 'Device location paths', value: (<>
                  {selectedGroup![0]?.assetPaths?.map((path) => (
                    <div key={path}>{path}</div>
                    ))}
                  {selectedGroup![0]?.assetPaths?.length === 0 && '-'}
                </>)
                },
            ]}
        />
    );
};

export default DeviceGroupDetails;
