import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useContext, createContext, useState, useMemo, useEffect } from "react";

import { userManagerAPI } from "api";
import { API_URL_PATH_GROUP_LIST } from "constants/urls";
import useRemoveDuplicateMeasurements from "hooks/useDeviceMeasurements";
import useFetch from "hooks/useFetch";
import { Asset, DeviceGroup } from "types/custom";

type AlertRulesManagerContextType = {
    allGroups: DeviceGroup[];
    selectedGroup: DeviceGroup | undefined;
    setSelectedGroup: React.Dispatch<React.SetStateAction<DeviceGroup | undefined>>;
    allDevices: Asset[];
    setAllDevices: React.Dispatch<React.SetStateAction<Asset[]>>;
    selectedDevices: Asset[];
    setSelectedDevices: React.Dispatch<React.SetStateAction<Asset[]>>;
    nonDuplicateMeasurements: string[];
    refetchGroups: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>;
    allGroupsStatus: "error" | "success" | "pending";
    isGroupsLoading: boolean;
}

const AlertRulesManagerContext = createContext<AlertRulesManagerContextType>({
    allGroups: [],
    selectedGroup: undefined,
    setSelectedGroup: () => { },
    allDevices: [],
    setAllDevices: () => { },
    selectedDevices: [],
    setSelectedDevices: () => { },
    nonDuplicateMeasurements: [],
    refetchGroups: async (options?: RefetchOptions) => {
        return Promise.resolve<any>({ data: null, error: null });
    },
    allGroupsStatus: 'pending',
    isGroupsLoading: true,

});

export const useAlertRulesManagerContext = () => {
    const context = useContext(AlertRulesManagerContext);

    if (!context) {
        throw new Error('useAlertManagerContext must be used within an AlertRulesManagerProvider');
    }

    return context;
}

const AlertRulesManagerProvider = ({ children }: { children: React.ReactNode }) => {
    const [allGroups, setAllGroups] = useState<DeviceGroup[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<DeviceGroup>();
    const [allDevices, setAllDevices] = useState<Asset[]>([]);
    const [selectedDevices, setSelectedDevices] = useState<Asset[]>([]);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const nonDuplicateMeasurements = useRemoveDuplicateMeasurements({ selectedDevices, allDevices })

    const {
        data: allGroupsResponse,
        isFetching: isGroupsLoading,
        status: allGroupsStatus,
        refetch: refetchGroups
    } = useFetch<{ items: DeviceGroup[] }>({
        axiosInstance: userManagerAPI,
        key: 'device-groups',
        url: API_URL_PATH_GROUP_LIST,
    });

    useEffect(() => {
        if (allGroupsStatus === 'success') {
            setAllGroups(allGroupsResponse.items || []);
            if (!isInitialized && allGroupsResponse.items && allGroupsResponse.items.length > 0) {
                setSelectedGroup(allGroupsResponse.items[0])
                setIsInitialized(true)
            }
        }
    }, [allGroupsResponse, allGroupsStatus]);

    const values = useMemo(() => ({
        allGroups,
        selectedGroup,
        setSelectedGroup,
        allDevices,
        setAllDevices,
        selectedDevices,
        setSelectedDevices,
        nonDuplicateMeasurements,
        isGroupsLoading,
        allGroupsStatus,
        refetchGroups,
    }), [
        allGroups,
        selectedGroup,
        setSelectedGroup,
        allDevices,
        setAllDevices,
        selectedDevices,
        setSelectedDevices,
        nonDuplicateMeasurements,
        isGroupsLoading,
        allGroupsStatus,
        refetchGroups,
    ]);

    return (
      <AlertRulesManagerContext.Provider value={values}>
        {children}
      </AlertRulesManagerContext.Provider>)
};

export default AlertRulesManagerProvider;