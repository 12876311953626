import {
  Modal,
  Box,
  SpaceBetween,
  Button,
  FormField,
  Input,
  Form,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { userManagerAPI } from 'api';
import { API_URL_PATH_GROUP_LIST } from 'constants/urls';
import { DeviceGroup } from 'types/custom';
import useMutation from 'hooks/useMutation';

const UpdateDeviceGroupModal = ({
  selectedGroup,
  visible,
  onDiscard,
  refetch,
}: {
  selectedGroup: DeviceGroup[];
  visible: boolean;
  onDiscard: () => void;
  refetch: () => void;
}) => {
  const [groupId, setGroupId] = useState(selectedGroup[0].groupId);
  const [description, setDescription] = useState(
    selectedGroup[0].description
  );
  const [friendlyName, setFriendlyName] = useState(
    selectedGroup[0].friendlyName
  );

  const {
    mutateAsync: updateDeviceGroup,
    status: updateDeviceGroupStatus,
    error: updateDeviceGroupError,
    isPending: updateDeviceGroupLoading,
  } = useMutation<DeviceGroup, {
    description: string;
    friendlyName: string;
  }>({
    api: userManagerAPI,
    method: 'PATCH',
    url: `${API_URL_PATH_GROUP_LIST}/${selectedGroup[0].groupId}`,
  });

  useEffect(() => {
    if (updateDeviceGroupStatus === 'success') {
      refetch();
      onDiscard();
    }
  }, [updateDeviceGroupStatus]);

  return (
    <Modal
      onDismiss={onDiscard}
      visible={visible}
      closeAriaLabel='Close modal'
      footer={
        <Box float='right'>
          <SpaceBetween direction='horizontal' size='xs'>
            <Button variant='link' onClick={onDiscard}>
              Cancel
            </Button>
            <Button
              variant='primary'
              onClick={async () => await updateDeviceGroup({
                description,
                friendlyName,
              })}
              loading={updateDeviceGroupLoading}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
      header='Update Device Group'
    >
      <Form errorText={updateDeviceGroupError?.message}>
        <SpaceBetween direction='vertical' size='l'>
          <FormField label='Group Name'>
            <Input
              value={groupId}
              disabled
              onChange={(event) => setGroupId(event.detail.value)}
            />
          </FormField>
          <FormField
            label={
              <span>
                Description <i>- optional</i>{' '}
              </span>
            }
          >
            <Input
              value={description}
              onChange={(event) =>
                setDescription(event.detail.value)
              }
            />
          </FormField>
          <FormField label='Friendly Name'>
            <Input
              value={friendlyName}
              onChange={(event) =>
                setFriendlyName(event.detail.value)
              }
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </Modal>
  );
};

export default UpdateDeviceGroupModal;
