import { Modal, Box, Header, Button, KeyValuePairs, Badge, Table, TextFilter, SpaceBetween } from "@cloudscape-design/components";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { useState } from "react";

import { snakeCaseToNormalCase } from "components/device-card/utils";
import AlertDetailsModal from "components/device-manager/DeviceAlertMgr/AlertDetailsModal";
import { EmptyState } from "components/empty-state/EmptyState";
import IncidentStatusBadge from "components/incident-status/IncidentStatusBadge";
import { AlertRule, EscalationStage, Incident } from "types/custom";
import { getMatchesCountText, thresholdCodeToName, toTitleCase } from "utils";
import { PaginationContent } from "components/table-content";

import IncidentAddActivity from "../IncidentAddActivity";

const IncidentDetailsModal = ({
  visible,
  setVisible,
  incident,
  alertRule,
  escalationStages,
  refetchIncidents,
  loading,
}: {
  visible: boolean;
  setVisible: (state: boolean) => void;
  incident: Incident;
  alertRule: AlertRule;
  escalationStages: EscalationStage[] | undefined;
  refetchIncidents: () => void;
  loading?: boolean;
}) => {
  const [showAlertDetailsModal, setShowAlertDetailsModal] = useState(false);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(incident.activities.slice().reverse(), {
    filtering: {
      empty: (
        <EmptyState
          title="No activities"
          subtitle="There are no activities for this incident yet."
        />
      ),
      noMatch: (
        <EmptyState
          title='No matches'
          subtitle='No matches were found.'
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: 15 },
  });

  const escalationStageToText = (stage: number) => {
    if (!escalationStages) return 'No data'
    if (stage === -1) return 'Disabled'
    return escalationStages[incident.escalationStage]?.notificationGroup ?? '-';
  }

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      header={
        <Header
          actions={
            <Button
              disabled={!alertRule}
              disabledReason='Alert rule not available'
              onClick={() => setShowAlertDetailsModal(true)}>
              View Alert rule
            </Button>
          }>
          Incident Details
        </Header>
      }
      size="large"
      footer={`Incident ID: ${incident.id}`}
    >
      <SpaceBetween direction="vertical" size="xl">
        <KeyValuePairs
          columns={3}
          items={[
            { label: 'Status', value: (<IncidentStatusBadge status={incident.status} />) },
            { label: 'Started', value: incident.createdAt ? new Date(incident.createdAt * 1000).toLocaleString() : '-' },
            { label: 'Device ID', value: incident.resourceId },
            { label: 'Current Escalation Stage', value: (<>{escalationStageToText(incident.escalationStage)} <Badge>{incident.escalationStage}</Badge></>) },
            { label: 'Measure', value: snakeCaseToNormalCase(alertRule.measure) ?? '-', info: 'From rule' },
            { label: 'Threshold Code', value: thresholdCodeToName(alertRule.threshold_code) || '-', info: 'From rule' },
            { label: 'Threshold Value', value: alertRule.threshold_value, info: 'From rule' },
            { label: 'Escalation Plan', value: alertRule.escalation_plan, info: 'From rule' },
          ]}
        />

        <Table
          {...collectionProps}
          header={
            <Header
              counter={
                items?.length
                  ? '(' +
                  items.length +
                  `/${incident.activities && incident.activities.length})`
                  : `(${incident.activities && incident.activities.length})`
              }
              actions={
                <IncidentAddActivity incidents={[incident]} refetchIncidents={refetchIncidents} loading={loading} />
              }
            >
              Activities
            </Header>
          }
          filter={
            <TextFilter
              {...filterProps}
              countText={getMatchesCountText(filteredItemsCount)}
              filteringPlaceholder='Find'
            />
          }
          pagination={
            <PaginationContent paginationProps={paginationProps} />
          }
          items={items}
          wrapLines={true}
          stripedRows={true}
          variant='container'
          columnDefinitions={[
            {
              id: 'type',
              header: 'Type',
              cell: item => toTitleCase(item.type),
            },
            {
              id: 'value',
              header: 'Value',
              cell: item => isNaN(parseInt(item.value)) ? (item.value ?? '-') : (<Badge>{item.value}</Badge>),
            },
            {
              id: 'time',
              header: 'Time',
              cell: item => item.time ? new Date(item.time).toLocaleString() : '-',
            },
            {
              id: 'user',
              header: 'User',
              cell: item => item.user || '-',
            },
          ]}
          empty={<Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">No activities</Box>}
        />
      </SpaceBetween>
      {showAlertDetailsModal && (
        <AlertDetailsModal
          visible={showAlertDetailsModal}
          setVisible={setShowAlertDetailsModal}
          alertRule={alertRule}
        />
      )}
    </Modal>
  )
}

export default IncidentDetailsModal;