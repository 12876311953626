import { Auth } from "aws-amplify";

import { Asset } from "types/custom";

import { deviceManagerAPI } from "..";
import { API_URL_PATH_DM_DEVICE } from "../../constants/urls";
import { DeviceTelemetry } from "../../components/device-chart/types";

export const getChartData = async ({ queryKey }:
  {
    queryKey: [{
      device: string, measurement: string, from: number, to: number
    }]
  }) => {

  const [{ device, measurement, from, to }] = queryKey;
  const url = `${API_URL_PATH_DM_DEVICE}/${device}/history/?measurement=${measurement}&from=${from}&to=${to}`;
  const response = await deviceManagerAPI.get(url, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });

  return response.data.map(([x, y]: DeviceTelemetry) => {
    return [x, y, device];
  }
  );
};


export const getDevice = async ({ id }: { id: string }): Promise<Asset> => {
  const response = await  deviceManagerAPI.get(`${API_URL_PATH_DM_DEVICE}/${id}`, {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    }
  });

  return response.data;
};