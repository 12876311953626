import {
    Button,
    Pagination,
} from '@cloudscape-design/components';

import { EmptyState } from 'components/empty-state/EmptyState';

export const EmptyStateContent = ({ name = 'instances' }: { name?: string }) => {
    return (
        <EmptyState
            title={`No ${name}`}
            subtitle={`No ${name} found.`}
            action={undefined}
        />
    );
};

export const NoMatchContent = ({ actions }: any) => {
    return (
        <EmptyState
            title='No matches'
            subtitle='No matches found.'
            action={
                <Button onClick={() => actions.setFiltering('')}>
                    Clear filter
                </Button>
            }
        />
    );
};

export const PaginationContent = ({ paginationProps }: any) => {
    return (
        <Pagination
            {...paginationProps}
            ariaLabels={{
                nextPageLabel: 'Next page',
                previousPageLabel: 'Previous page',
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
        />
    );
};
