import { Alert, Box, Button, KeyValuePairs, Modal, SpaceBetween, Toggle } from '@cloudscape-design/components';
import { useState } from 'react';

import { deviceManagerAPI } from 'api';
import { API_URL_PATH_DM_RULE } from 'constants/urls';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import { AlertRule, IncidentStatus } from 'types/custom';
import { thresholdCodeToName } from 'utils';
import { relativeToText, secondsToRelative } from 'utils/relativeTime';
import IncidentStatusBadge from 'components/incident-status/IncidentStatusBadge';
import useFetch from 'hooks/useFetch';

import AlertStatusIndicator from '../AlertStatusIndicator';

const AlertDetailsModal = ({
  visible,
  setVisible,
  alertRule: initialAlertRule,
}: {
  visible: boolean;
  setVisible: (state: boolean) => void;
  alertRule: AlertRule;
}) => {
  const [alertRule, setAlertRule] = useState<AlertRule>(initialAlertRule);

  const {
    data: previousAlertRule,
    status: previousAlertRuleStatus,
    error: previousAlertRuleError,
    isLoading: previousAlertRuleLoading,
  } = useFetch<AlertRule>({
    key: `previousAlertRule-${alertRule.previous_version_id}`,
    axiosInstance: deviceManagerAPI,
    url: `${API_URL_PATH_DM_RULE}/${alertRule.previous_version_id}`,
    enabled: !!alertRule.previous_version_id,
  });

  const goToPreviousVersion = async () => {
    if (previousAlertRuleStatus === 'success' && previousAlertRule) {
      setAlertRule(previousAlertRule);
    }
  }

  const idDetailsItem = alertRule.deviceGroupId ?
    { label: 'Device Group Id', value: alertRule.deviceGroupId }
    : { label: 'Device ID', value: alertRule.deviceId }

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel='Close modal'
      header='Alert Rule Details'
      size='medium'
      footer={`Alert Rule ID: ${alertRule.id}`}
    >
      <div>
        <KeyValuePairs
          columns={2}
          items={[
            { label: 'Status', value: (<AlertStatusIndicator status={alertRule.status} />) },
            idDetailsItem,
            { label: 'Measure', value: snakeCaseToNormalCase(alertRule.measure) },
            { label: 'Threshold Code', value: thresholdCodeToName(alertRule.threshold_code) },
            { label: 'Threshold Value', value: alertRule.threshold_value },
            { label: 'Deadband Period', value: relativeToText(secondsToRelative(alertRule.deadband_period), '') || (<i>Disabled</i>) },
            { label: 'Escalation Plan', value: alertRule.escalation_plan || (<i>None</i>) },
            { label: 'Forward to Central Monitoring', value: <Toggle checked={alertRule.forward} readOnly={true}>{alertRule.forward ? 'Enabled' : 'Disabled'}</Toggle> },
            { label: 'Created By', value: alertRule.createdBy || '-' },
            { label: 'Updated By', value: alertRule.updatedBy || '-' },
            { label: 'Created At', value: new Date(alertRule.createdAt * 1000).toLocaleString(undefined, { timeZoneName: "shortOffset" }) },
            { label: 'Updated At', value: new Date(alertRule.updatedAt * 1000).toLocaleString(undefined, { timeZoneName: "shortOffset" }) },
            {
              label: 'Notification Frequency', value: (<>
                <div><IncidentStatusBadge status={IncidentStatus.OPEN} />
                  &nbsp; or &nbsp;
                  <IncidentStatusBadge status={IncidentStatus.FORWARDED} />
                  : {
                    relativeToText(secondsToRelative(alertRule.notification_frequency[IncidentStatus.OPEN]), 'Never skip', 'Always skip')
                  }</div>
                <div><IncidentStatusBadge status={IncidentStatus.CLEARED} />: {
                  relativeToText(secondsToRelative(alertRule.notification_frequency[IncidentStatus.CLEARED]), 'Never skip', 'Always skip')
                }</div>
                <div><IncidentStatusBadge status={IncidentStatus.ACKNOWLEDGED} />: {
                  relativeToText(secondsToRelative(alertRule.notification_frequency[IncidentStatus.ACKNOWLEDGED]), 'Never skip', 'Always skip')
                }</div>
              </>)
            },
            { label: 'Notes', value: alertRule.notes || '-' },
          ]}
        />
        <Box margin={{ 'top': 'l' }}>
          <SpaceBetween direction='horizontal' size='s'>
            <Button
              iconName='arrow-left'
              loading={previousAlertRuleLoading}
              disabled={!alertRule.previous_version_id}
              disabledReason='No previous version'
              onClick={goToPreviousVersion}>
              Previous Version
            </Button>
            <Button
              iconName='arrow-right'
              iconAlign='right'
              disabled={initialAlertRule.id === alertRule.id}
              disabledReason='No newer version'
              onClick={() => setAlertRule(initialAlertRule)}>
              Latest Version
            </Button>
          </SpaceBetween>
        </Box>
        {previousAlertRuleError && <Box margin={{ 'top': 'l' }}>
          <Alert type='error'>{previousAlertRuleError.message}</Alert>
        </Box>}
      </div>
    </Modal>
  );
};

export default AlertDetailsModal;