import { Popover, SpaceBetween, SegmentedControl, Textarea, Button } from "@cloudscape-design/components";
import { useState } from "react";

import { incidentManagerAPI } from "api";
import { API_URL_PATH_IM_INCIDENTS } from "constants/urls";
import { Incident, IncidentActivity, IncidentStatus } from "types/custom";
import useMutation from "hooks/useMutation";

function IncidentAddActivity({
	incidents,
	refetchIncidents,
	loading,
}: {
	incidents: Incident[];
	refetchIncidents: () => void;
	loading?: boolean;
}) {
	const [newActivityValue, setNewActivityValue] = useState('');
	const [newActivityType, setNewActivityType] = useState('');

	const {
		mutateAsync: addNewActivity,
		isPending: isAddingNewActivity,
		error: addActivityError,
	} = useMutation<Incident[], {
		type: string;
		value: string;
	}>({
		api: incidentManagerAPI,
		method: 'PATCH',
		url: `${API_URL_PATH_IM_INCIDENTS}/${incidents.map((x) => x.id).join(',')}`,
		notifications: [
			{type: 'success', content: 'Successfully added event to incident'},
			{type: 'error'}
		]
	});

	const handleNewActivity = async () => {
		const result = await addNewActivity({
			type: newActivityType,
			value: newActivityValue,
		});

		if(!result) return

		if (result.status >= 200 && result.status < 300) {
			refetchIncidents();
		}
		setNewActivityType('');
		setNewActivityValue('');
	}

	const isResolved = incidents?.some(x => x.status === IncidentStatus.RESOLVED);
	const isDisabled = isAddingNewActivity || loading || !incidents.length || isResolved;

	return (
  <Popover
			size='large'
			header='Actions'
			dismissButton={true}
			triggerType='custom'
			content={
  <SpaceBetween size='xs'>
    <SegmentedControl
						selectedId={newActivityType || null}
						onChange={({ detail }) => {
							setNewActivityType(detail.selectedId);
						}}
						options={[
							{
								text: 'Comment',
								id: IncidentActivity.COMMENT,
								disabled: isDisabled,
							},
							{
								text: 'Acknowledge',
								id: IncidentActivity.ACKNOWLEDGE,
								disabled: isDisabled,
							},
							{
								text: 'Resolve',
								id: IncidentActivity.RESOLUTION,
								disabled: isDisabled,
							}
						]}
					/>
    <Textarea
						value={newActivityValue}
						onChange={(e) => setNewActivityValue(e.detail.value)}
						placeholder='Add details here'
						disabled={isDisabled}
					/>
    <Button
						variant='primary'
						onClick={handleNewActivity}
						loading={isAddingNewActivity || loading}
						disabled={isResolved || !newActivityType || !newActivityValue}
						disabledReason='Incident is resolved or fields are empty'
					>
      Add
    </Button>
  </SpaceBetween>
			}>
    <Button
				loading={isAddingNewActivity}
				disabled={isDisabled}
				disabledReason='Incident is resolved or fields are empty'
			>Actions</Button>
  </Popover>
	)
}

export default IncidentAddActivity;