import { /* Button, */ SegmentedControl, SpaceBetween } from '@cloudscape-design/components';

import { useDashboardContext } from 'providers/DashboardProvider';

import HierarchyTree from './hierarchy/HierarchyTree';
import LocationTree from './location/LocationTree';

const LocationHierarchy = () => {
    const { isUsingLocationsHierarchy, setIsUsingLocationsHierarchy /* , expandAll, setExpandAll */ } = useDashboardContext();

    return (
      <SpaceBetween direction='vertical' size='s'>
        <SpaceBetween size='xxs' direction='horizontal'>
          <SegmentedControl
                    selectedId={isUsingLocationsHierarchy ? "locations" : "tags"}
                    onChange={({detail}) =>
                        setIsUsingLocationsHierarchy(detail.selectedId === "locations")
                    }
                    label=""
                    options={[
                        { text: "Tags", id: "tags" },
                        { text: "Locations", id: "locations" },
                    ]}
                />
          {/* 
              {!isUsingLocationsHierarchy && (
                <Button 
                  variant="normal" 
                  loading={expandAll} 
                  onClick={() => setExpandAll(!expandAll)}
                >
                  Expand all
                </Button>
              )} 
            */}
          {/* 
              The Expand All button negatively affects performance, as it'd need to render all the devices and their tags.
              With the latest performance improvements, it shouldn't be needed anymore.
              Also, the UI looks better without that button. 
            */}
          {/* 
              Current behavior of Expand All is to expand all the nodes that have been expanded at least once,
              as those are the rendered ones. 
            */}
        </SpaceBetween>
        {isUsingLocationsHierarchy ? <LocationTree /> : <HierarchyTree />}
      </SpaceBetween>
    );
};

export default LocationHierarchy;
