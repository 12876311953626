import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';

const DeleteModal = ({
  visible,
  areActionsDisabled,
  onDiscard,
  onDelete,
  itemName,
  moduleName,
  loading,
  isNotPermanent,
  action,
}: {
  visible: boolean;
  areActionsDisabled?: boolean;
  onDiscard: () => void;
  onDelete: () => void;
  itemName: any[] | string[] | undefined;
  itemCount: any;
  moduleName: string;
  loading?: boolean;
  isNotPermanent?: boolean;
  action?: string;
}) => {
  const names = (itemName: any[] | undefined) =>
    itemName?.map((item) => typeof item === 'string' ? item : (item?.name || item?.friendlyName)).filter(Boolean) || [];

  return (
    <>
      <Modal
        onDismiss={onDiscard}
        visible={visible}
        closeAriaLabel='Close modal'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={onDiscard} disabled={areActionsDisabled}>
                Cancel
              </Button>
              <Button variant='primary' onClick={onDelete} loading={loading} disabled={areActionsDisabled}>
                {action || 'Delete'}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={`${action || 'Delete ' + moduleName}`}
      >
        Are you sure you want to {action?.toLowerCase() || 'delete'} the following
        {' ' + moduleName.toLowerCase()}{isNotPermanent ? '' : ' permanently'}?
        {!isNotPermanent && <div>
          <i>You can't undo this action. </i>
        </div>
        }
        <b>
          <ul>
            {names(itemName)?.map(name => <li key={name}>{name}</li>)}
          </ul>
        </b>
      </Modal>
    </>
  );
};

export default DeleteModal;
