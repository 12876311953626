import { SpaceBetween } from '@cloudscape-design/components';
import { createContext, useContext, useEffect, useState } from 'react';

import { userManagerAPI } from 'api';
import DeviceGroupTable from 'components/device-group-manager/DeviceGroupTable';
import DeviceGroupTabs from 'components/device-group-manager/DeviceGroupTabs';
import { API_URL_PATH_GROUP_LIST } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { DeviceGroup } from 'types/custom';

export interface AssetProps {
    assetCategory: string;
    assetId: string;
}

const DeviceGroupsContext = createContext({
    allGroups: [] as DeviceGroup[],
    isLoading: false,
    isFetching: false,
    selectedGroup: [] as DeviceGroup[],
    setSelectedGroup: (state: DeviceGroup[]) => { },
    refetch: () => { },
});

export const useDeviceGroupsContext = () => useContext(DeviceGroupsContext);

const DeviceGroupListPage = () => {
    const [allGroups, setAllGroups] = useState<DeviceGroup[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<DeviceGroup[]>([]);

    const {
        data,
        isLoading,
        isFetching,
        status,
        refetch
    } = useFetch<{ items: DeviceGroup[] }>({
        axiosInstance: userManagerAPI,
        url: API_URL_PATH_GROUP_LIST,
        key: 'device-group-list',
    });

    useEffect(() => {
        if (status === 'success') {
            setAllGroups(data?.items || []);
        }
    }, [status, data, refetch]);

    return (
      <DeviceGroupsContext.Provider
            value={{
                isLoading,
                isFetching,
                allGroups,
                selectedGroup,
                setSelectedGroup,
                refetch,
            }}
        >
          <SpaceBetween direction='vertical' size='m'>
            <DeviceGroupTable />
            {allGroups && selectedGroup![0]?.groupId && <DeviceGroupTabs />}
          </SpaceBetween>
      </DeviceGroupsContext.Provider>
    );
};

export default DeviceGroupListPage;
