import {
  Grid,
  Container,
  Header,
  Button,
} from '@cloudscape-design/components';

import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

import GroupsList from './GroupsList';
import GroupsView from './GroupsView';


const NotificationGroups = () => {
  const { groupsRefetch, groupsFetching } = useNotificationManagerContext();
  return (
    <Grid
      gridDefinition={[
        { colspan: { m: 4, s: 3, xs: 12, xxs: 12 } },
        { colspan: { m: 8, s: 9, xs: 12, xxs: 12 } },
      ]}
    >
      <Container
        className='custom-height-container custom-scroll-container'
        header={<Header
          variant='h3'
          actions={<Button
            iconName='refresh'
            onClick={groupsRefetch}
            loading={groupsFetching} />}>
          Notification Groups
        </Header>}>
        <GroupsList />
      </Container>

      <Container className='custom-height-container custom-scroll-container'>
        <GroupsView />
      </Container>
    </Grid>
  );
};

export default NotificationGroups;
