import { Container } from '@cloudscape-design/components';

import CreateRole from 'components/role-manager/CreateRole';

const CreateRolePage = () => {
    return (
      <Container>
        <CreateRole />
      </Container>
    );
};

export default CreateRolePage;
