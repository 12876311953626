import { KeyValuePairs } from '@cloudscape-design/components';

import { snakeCaseToNormalCase } from 'components/device-card/utils';
import StatePopover from 'components/state-popover/StatePopover';
import { Asset } from 'types/custom';
import DeviceStateBadge from 'components/device-state-badge/DeviceStateBadge';

const DeviceDetails = ({ selectedDevices }:{ selectedDevices: Asset }) => {
    return (
      <KeyValuePairs
        columns={3}
        items={[
            { label: 'Name', value: selectedDevices.name || '-' },
            { label: 'Friendly Name', value: selectedDevices.friendlyName || '-' },
            { label: 'State', value: (<DeviceStateBadge state={selectedDevices.deviceState} />) },
            { label: 'Description', value: selectedDevices.description || '-' },
            { label: 'Device Type ID', value: selectedDevices.deviceTypeId || '-' },
            { label: 'Invoice ID', value: selectedDevices.invoiceId || '-' },
            { label: 'Physical Device ID', value: selectedDevices.physicalDeviceId || '-' },
            { label: 'Lookup ID', value: selectedDevices.lookupId || '-' },
            { label: 'Updated At', value: selectedDevices.updatedAt ? new Date(selectedDevices.updatedAt * 1000).toLocaleString(undefined, {timeZoneName: "shortOffset"}) : '-' },
            { label: 'Current Path', value: selectedDevices.currentPath || '-' },
            { label: 'Friendly Current Path', value: selectedDevices.friendlyCurrentPath || '-' },
            ...Object.entries(selectedDevices.state || {}).map(([measurement, state]) => ({
                label: `Measurement: ${snakeCaseToNormalCase(measurement)}`,
                value: (<StatePopover measurement={measurement} state={state}>{state.value ?? '-'}</StatePopover>)
            })),
        ]}
        />
    );
};

export default DeviceDetails;
