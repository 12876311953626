import { createContext, useContext, useState } from 'react';
import { SpaceBetween } from '@cloudscape-design/components';
import { AxiosError, AxiosResponse } from 'axios';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';

import DeviceTable from 'components/device-manager/DeviceTable';
import DeviceTabs from 'components/device-manager/DeviceTabs';
import { deviceManagerAPI } from 'api';
import {
    API_URL_PATH_DM_DEVICE_LIST,
    API_URL_PATH_DM_SYNC_DEVICES,
} from 'constants/urls';
import { Asset } from 'types/custom';
import useFetch from 'hooks/useFetch';
import useMutation from 'hooks/useMutation';

export const DeviceManagerContext = createContext<{
    allDevices: Asset[];
    deviceLoading: boolean;
    deviceError: Error | null;
    setSelectedDevices: (asset: Asset[]) => void;
    selectedDevices: Asset[];
    getAllDevices: (options?: RefetchOptions) => Promise<QueryObserverResult<Asset[], Error>>;
    activeTabId: string;
    setActiveTabId: (state: string) => void;
    deviceFetching: boolean;
    syncDevices: () => Promise<AxiosResponse<void, AxiosError> | undefined>;
    isSyncingDevices: boolean;
    countAlertRules?: boolean;
}>({
    allDevices: [],
    deviceLoading: false,
    deviceError: null,
    setSelectedDevices: () => { },
    selectedDevices: [],
    getAllDevices: async (_options?: RefetchOptions) => {
        return {} as QueryObserverResult<Asset[], Error>;
    },
    activeTabId: '',
    setActiveTabId: () => { },
    deviceFetching: false,
    syncDevices: async () => {
        return {} as AxiosResponse
    },
    isSyncingDevices: false,
    countAlertRules: false,
});

export const useDeviceManagerContext = () => useContext(DeviceManagerContext);

const DeviceListPage = () => {
    const [selectedDevices, setSelectedDevices] = useState<Asset[]>([]);
    const [activeTabId, setActiveTabId] = useState<string>('details');

    const {
        data: devices,
        error: deviceError,
        isLoading: deviceLoading,
        refetch: getDevices,
        isFetching: deviceFetching
    } = useFetch<Asset[]>(
        {
            axiosInstance: deviceManagerAPI,
            url: API_URL_PATH_DM_DEVICE_LIST,
            key: 'devices',
        },
    );

    const {
        mutateAsync: syncDevices,
        isPending: isSyncingDevices,
    } = useMutation<void, {}>(
        {
            api: deviceManagerAPI,
            url: API_URL_PATH_DM_SYNC_DEVICES,
            method: "POST",
        },
    );

    return (
        <DeviceManagerContext.Provider
            value={{
                deviceLoading,
                allDevices: devices || [],
                deviceError,
                setSelectedDevices,
                selectedDevices,
                activeTabId,
                setActiveTabId,
                getAllDevices: getDevices,
                deviceFetching,
                isSyncingDevices,
                syncDevices: () => syncDevices({}),
            }}
        >
            <SpaceBetween direction='vertical' size='m'>
                <DeviceTable showActions selectionType='multi' />
                {devices && (
                    <DeviceTabs selectedDevices={selectedDevices} isOnDeviceManagerPage={true} />
                )}
            </SpaceBetween>
        </DeviceManagerContext.Provider>
    );
};

export default DeviceListPage;