import { useQuery } from '@tanstack/react-query'

import { getDevice } from '../fetchers/device-manager'

function useGetDevice({ id }: { id: string }) {
  return useQuery({
    queryKey: ['device', { id }],
    queryFn: () => getDevice({ id }),
  })
};

export default useGetDevice;
