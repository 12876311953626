import { CardHeader, Checkbox } from '@mui/material';

export const CustomCardHeader = ({
    title,
    items,
    numberOfChecked,
    handleToggleAll,
}: {
    title: string;
    items: any[];
    numberOfChecked: (items: any[]) => number;
    handleToggleAll: (items: any[]) => () => void;
}) => {
    return (
      <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={
              <Checkbox
                    onClick={handleToggleAll(items)}
                    checked={
                        numberOfChecked(items) === items.length &&
                        items.length !== 0
                    }
                    indeterminate={
                        numberOfChecked(items) !== items.length &&
                        numberOfChecked(items) !== 0
                    }
                    disabled={items.length === 0}
                    inputProps={{
                        'aria-label': 'all items selected',
                    }}
                />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
    );
};
