import { Button, FormField, Multiselect, SelectProps } from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { useEffect, useState } from "react";

import { usePageLayoutContext } from "components/common/layout";
import { deviceManagerAPI } from "api";
import { API_URL_PATH_TAG } from "constants/urls";
import { Asset, HierarchyNode, Tag } from "types/custom";
import useMutation from "hooks/useMutation";

import { makeNodeFromOption, naturalSortNode, naturalSortString } from "./utils";

export type AddDeviceModalProps = {
  node: HierarchyNode;
  onNodeAdded: (parent: HierarchyNode) => void;
  dismissModal: () => void;
  allDevices: Asset[];
  enabledDevices: SelectProps.Options | undefined;
  devicesPresentInTree: string[];
  setDevicesPresentInTree: (devices: string[]) => void;
}

export function AddDevicesModal({
  node,
  onNodeAdded,
  dismissModal,
  allDevices,
  enabledDevices,
  devicesPresentInTree,
  setDevicesPresentInTree,
}: AddDeviceModalProps) {
  const [selectedDevices, setSelectedDevices] = useState<OptionDefinition[]>([]);
  const [isAddingDevicesToTag, setIsAddingDevicesToTag] = useState(false);
  const { setNotification } = usePageLayoutContext();

  const {
    mutateAsync: addDevicesToTag,
  } = useMutation<Tag, {
    fullUrl: string;
    assetList: string[];
    runDuplicateAssetValidation: boolean
  }>({
    api: deviceManagerAPI,
    method: 'PATCH',
    notifications:[
      {type: 'success', content: 'Devices added successfully'},
      {type: 'error', content: 'Failed to add devices to the tag.', shouldAppendError: true}
    ]
  });

  const onAddDevicesToTag = async () => {
    if (isAddingDevicesToTag) return;
    if (selectedDevices.length === 0) {
      setNotification([{
        type: 'error',
        content: 'Please select at least one device to add to the tag',
      }]);
      return;
    }
    setIsAddingDevicesToTag(true);

    // Removes deleted or orphaned assets from the asset list
    // TODO: This should be done on backend side
    const existingAssets = node?.assetList?.filter((name) => allDevices?.find((asset) => asset.name === name)) || [];
    // Obtains the selected devices
    const newAssets = selectedDevices.map((device) => device.value!);

    const response = await addDevicesToTag({
      // Combines and removes duplicates, and sorts by natural order
      fullUrl: `${API_URL_PATH_TAG}/${node.id}`,
      assetList: Array.from(new Set([...existingAssets, ...newAssets])).sort(naturalSortString),
      runDuplicateAssetValidation: false,
    });

    if (response && response.status === 200) {

      node.children = [
        ...node.children,
        ...selectedDevices.map(makeNodeFromOption)
      ].sort(naturalSortNode);
      onNodeAdded(node);

      setDevicesPresentInTree([...devicesPresentInTree, ...newAssets]);
    }

    setSelectedDevices([]);
    dismissModal();
    setIsAddingDevicesToTag(false);
  };

  useEffect(() => {
    setSelectedDevices([]);
  }, [node]);

  return (
    <>
      <FormField label='Devices'>
        <Multiselect
          selectedOptions={selectedDevices}
          onChange={({ detail }) => setSelectedDevices(detail.selectedOptions as OptionDefinition[])}
          filteringType='auto'
          deselectAriaLabel={(e) => `Remove ${e.label}`}
          options={enabledDevices}
          placeholder='Choose devices to add'
          selectedAriaLabel='Selected'
          loadingText='Loading devices'
          empty='No options'
        />
      </FormField>

      <div style={{ marginTop: '20px' }}>
        <Button variant="primary" onClick={onAddDevicesToTag} loading={isAddingDevicesToTag}>Save</Button>
      </div>
    </>
  );
}
