import SegmentedControl from '@cloudscape-design/components/segmented-control';
import RectangleIcon from '@mui/icons-material/Rectangle';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import TableRowsIcon from '@mui/icons-material/TableRows';

import colors from 'theme/colors';
import { useDashboardContext } from 'providers/DashboardProvider';

export default function LayoutPicker() {
	const { layoutColumns, setLayoutColumns } = useDashboardContext();
	return (
  <div style={{backgroundColor: colors.accentGreen, borderRadius: '8px', boxShadow: '0px 0px 4px -1px black'}} title='Column layout picker'>
    <SegmentedControl
			selectedId={layoutColumns}
			onChange={({ detail }) => setLayoutColumns(detail.selectedId)}
			options={[
			{
				iconAlt: 'One column',
				iconSvg: <RectangleIcon />,
				id: '1',
			},
			{
				iconAlt: 'Two columns',
				iconSvg: <ViewAgendaIcon />,
				id: '2'
			},
			{
				iconAlt: 'Three columns',
				iconSvg: <TableRowsIcon />,
				id: '3'
			}
			]}
		/>
  </div>
	);
}