import { useEffect, useState } from "react";

import {
  API_URL_PATH_DM_DEVICE_LIST,
} from 'constants/urls';
import { Asset } from "types/custom";
import { deviceManagerAPI } from "api";
import Spinner from "components/spinner/Spinner";
import useFetch from "hooks/useFetch";
import IncidentsTable from "components/device-manager/DeviceIncidents/IncidentsTable";
import { extractMeasuresFromAllDevices } from "components/device-manager/DeviceIncidents/IncidentsTable/utils";

const IncidentManagerPage = () => {
  const [allDevices, setAllDevices] = useState<Asset[]>([]);

  const { data: deviceData, isLoading: isDevicesLoading, error: devicesError } = useFetch<Asset[]>({
    key: "devices",
    axiosInstance: deviceManagerAPI,
    url: API_URL_PATH_DM_DEVICE_LIST,
  });

  useEffect(() => {
    if (deviceData && deviceData.length > 0) {
      setAllDevices(deviceData);
    }

  }, [deviceData]);

  return (
    <>
      {(devicesError) && <div>No data found</div>}
      {isDevicesLoading && <Spinner />}
      <IncidentsTable
        variant="container"
        allDevices={allDevices}
        allMeasures={extractMeasuresFromAllDevices(allDevices)} />
    </>
  );
};

export default IncidentManagerPage;