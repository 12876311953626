import { AppLayout, Flashbar, FlashbarProps } from '@cloudscape-design/components';
import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';

import SideNav from 'components/common/sidenav';
import TopNav from 'components/common/topnav';

type NotificationWithId = Omit<FlashbarProps.MessageDefinition, 'dismissible' | 'dismissLabel' | 'onDismiss'>
export type Notification = Omit<NotificationWithId, 'id'>

const PageLayoutContext = createContext({
    setNotification: (state: Notification[]) => { },
});

export const usePageLayoutContext = () => useContext(PageLayoutContext);

const Layout = () => {
    const [internalNotification, internalSetNotifications] = useState<NotificationWithId[]>([]);

    return (
      <PageLayoutContext.Provider value={{
            setNotification: (newItems: Notification[]) => {
                // Group existing notifications with new ones.
                // Then set to distinct ids to ensure flash bar works well, overriding ids.
                internalSetNotifications(notifications => [...notifications, ...newItems].map((n, i) => ({...n, id: `n-${i}` })));
            }
      }}>
        <TopNav />
        <AppLayout
                toolsHide
                headerVariant="high-contrast"
                stickyNotifications={true}
                navigation={<SideNav />}
                content={<Outlet />}
                notifications={<Flashbar items={internalNotification.map((n) => ({
                    ...n,
                    // Always override the following props of the notifications
                    type: n.type || 'error',
                    content: n.content || 'An error occurred',
                    dismissible: true,
                    dismissLabel: 'Dismiss notification',
                    onDismiss: () => internalSetNotifications(notifications => notifications.filter(item => item.id !== n.id)),
                }) as FlashbarProps.MessageDefinition).reverse()} stackItems={true} />}
                disableContentPaddings
            />
      </PageLayoutContext.Provider>
    );
};

export default Layout;
