import ReactDOM from 'react-dom/client';
import './fonts/bi-sans-next/BISansNEXT-Regular.otf';
import './index.css';
import './runtimeConfig';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { applyTheme } from '@cloudscape-design/components/theming';

import { AuthProvider } from 'setup/app-context-manager/AuthProvider';

import App from './App';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

applyTheme({
    theme,
})

root.render(
    // <React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </QueryClientProvider>
  </BrowserRouter>
    // </React.StrictMode>
);
