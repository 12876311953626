import { useQuery } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { Auth } from "aws-amplify";

type UseFetchProps<T> = {
  key: string;
  url: string;
  axiosInstance: AxiosInstance;
  enabled?: boolean;
  placeholderData?: T;
}

const fetcherFunction = async (axiosInstance: AxiosInstance, url: string) => {
  const token = (await Auth.currentSession()).getIdToken().getJwtToken()

  const result = await axiosInstance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
}

const useFetch = <T = any>({ key, url, axiosInstance, enabled = true, placeholderData }: UseFetchProps<T>) => {
  const { data, error, isLoading, isFetching, status, refetch } = useQuery({
    queryKey: [key, url],
    queryFn: () => fetcherFunction(axiosInstance, url),
    enabled,
    retry: 3,
    refetchOnWindowFocus: false,
    placeholderData,
  });

  return { data: data as T, error, isLoading, isFetching, status, refetch }
}

export default useFetch;
