import { StatusIndicator, StatusIndicatorProps } from "@cloudscape-design/components";

import { IncidentStatus } from "types/custom";
import { toTitleCase } from "utils";

const statusToTypeMap: Record<IncidentStatus, StatusIndicatorProps.Type> = {
	[IncidentStatus.OPEN]: 'error',
	[IncidentStatus.CLEARED]: 'info',
	[IncidentStatus.RESOLVED]: 'success',
	[IncidentStatus.ACKNOWLEDGED]: 'warning',
	[IncidentStatus.FORWARDED]: 'info',
};

const IncidentStatusBadge = ({status}: {status: IncidentStatus}) => {
	return (<StatusIndicator type={statusToTypeMap[status] || 'info'}>
  {toTitleCase(status)}
	</StatusIndicator>);
}

export default IncidentStatusBadge;