import { DateRangePickerProps } from "@cloudscape-design/components";

import { relativeToSeconds } from "utils/relativeTime";

function getDateTimeRange(dateTimeRange: DateRangePickerProps.Value) {
  if (dateTimeRange.type === 'absolute') {
    return {
      from: Date.parse(dateTimeRange.startDate),
      to: Date.parse(dateTimeRange.endDate)
    }
  } else {
    return {
      from: Date.now() - relativeToSeconds(dateTimeRange) * 1000,
      to: Date.now(),
    }
  }
}

export const convertToTwelveHourFormat = (hour: string): string => {
  if (!hour || hour.trim() === '') {
    return '';
  }

  const hourInt = Number(hour);
  if (isNaN(hourInt) || hourInt < 0 || hourInt > 23) {
    throw new Error('Invalid hour input. Hour must be a string representing a number between 0 and 23.');
  }
  const twelveHour = hourInt % 12 === 0 ? 12 : hourInt % 12;
  const amPm = hourInt >= 12 ? 'PM' : 'AM';

  return `${twelveHour} ${amPm}`;
};


export const convertToTwentyFourHourFormat = (hour: string, postFix: string): string => {
  if (!hour || hour.trim() === '') {
    return '';
  }

  const hourInt = Number(hour);
  if (isNaN(hourInt) || hourInt < 1 || hourInt > 12) {
    throw new Error('Invalid hour input.');
  }

  if (postFix === 'AM') {
    return hourInt === 12 ? '00' : hourInt.toString().padStart(2, '0');
  } else {
    return hourInt === 12 ? '12' : (hourInt + 12).toString().padStart(2, '0');
  }
};

export default getDateTimeRange;
