import { memo, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import Slider, { sliderClasses } from '@mui/material/Slider';

import { ChartData } from 'components/device-chart/types';
import { useDashboardContext } from 'providers/DashboardProvider';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import { colorProgressionMap } from 'pages/dashboard/dashboard-page/utils';
import { createWeeklySeries, createDailySeries, parseDates } from 'components/device-chart/utils';

type HeatmapProps = {
  measurement: string;
  data: ChartData;
};

const Heatmap = ({
  onClick,
  series,
  colorRange,
  isDaily,
  measurement,
  palette,
  friendlyName,
  heatmapDailyDateRangeLabel,
}: any) => {
  return (
    <ReactApexChart
      series={series.map((s: any) => ({
        name: s.name,
        data: s.data.map((d: any) => ({
          x: d.x,
          y: d.y < colorRange[0] || d.y > colorRange[1] ? null : Number(d.y).toFixed(2),
        })),
      }))}
      type="heatmap"
      height={500}
      options={{
        chart: {
          height: 350,
          width: '100%',
          type: 'heatmap',
          events: {
            click: onClick,
          },
          toolbar: {
            export: {
              csv: {
                filename: `${friendlyName}-${measurement}`,
              },
              svg: {
                filename: `${friendlyName}-${measurement}`,
              },
              png: {
                filename: `${friendlyName}-${measurement}`,
              },
            }
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: `${isDaily ? 'Daily' : 'Weekly'
            } Heatmap for ${snakeCaseToNormalCase(measurement)}, ${friendlyName}${isDaily && heatmapDailyDateRangeLabel ? ', ' + heatmapDailyDateRangeLabel : ''}`,
        },
        yaxis: {
          reversed: true,
        },
        xaxis: {
          type: 'category',
          categories:
            series[0].data.map((d: any) => d.x),
        },
        tooltip: {
          enabled: true,
          y: {
            title: {
              formatter: () => '',
            },
            formatter: (val) => {
              const hasNoData = val === null;

              if (hasNoData) return 'No data found';

              return `${val}`;
            },
          },
        },
        stroke: {
          width: 1,
          colors: ['#B0BEC5'],
        },
        legend: {
          show: false,
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            },
          },
        },
        noData: {
          text: 'No data found',
          style: {
            color: '#FF0000'
          }
        },
        plotOptions: {
          heatmap: {
            enableShades: false,
            colorScale: {
              ranges: [
                {
                  from: colorRange[0],
                  to: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.2,
                  color: palette[4],
                },
                {
                  from: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.2,
                  to: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.4,
                  color: palette[3],
                },
                {
                  from: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.4,
                  to: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.6,
                  color: palette[2],
                },
                {
                  from: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.6,
                  to: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.8,
                  color: palette[1],
                },
                {
                  from: colorRange[0] + (colorRange[1] - colorRange[0]) * 0.8,
                  to: colorRange[0] + (colorRange[1] - colorRange[0]) * 1,
                  color: palette[0],
                },
                {
                  from: null as any as undefined,
                  to: null as any as undefined,
                  color: '#FFFFFF',
                },
              ],
            },
          }
        },
      }}
    />
  )
};

const MemoizedHeatmap = memo(Heatmap, (p, n) => {
  return JSON.stringify(p.colorRange) === JSON.stringify(n.colorRange) &&
        p.series.length === n.series.length &&
        p.series === n.series &&
        p.colorRange === n.colorRange &&
        p.isDaily === n.isDaily;
});

export default function CustomHeatmap({ data, measurement }: HeatmapProps) {
  const [heatmapDailyDateRangeLabel, setHeatmapDailyDateRangeLabel] = useState<string>();
  const { dateTimeRange } = useDashboardContext();
  const [isDaily, setIsDaily] = useState<boolean>(false);
  const [startDate, endDate] = parseDates(dateTimeRange);
  const [series, setSeries] = useState(createWeeklySeries(data, startDate, endDate));
  const min = Math.min(...data.data.map((x) => x.y));
  const max = Math.max(...data.data.map((x) => x.y));
  const [sliderRange, setSliderRange] = useState<[number, number]>([min, max]);
  const [heatmapColorRange, setHeatmapColorRange] = useState<[number, number]>([min, max]);
  const colorProgression = colorProgressionMap[data.data[0].color as keyof typeof colorProgressionMap];
  const friendlyName = data.data[0].friendlyName;

  const onChangeView = (__: any, _: any, options: any) => {
    const clickedElement: string = options.globals.labels[options.dataPointIndex];
    if (clickedElement.includes('-') && !isDaily) {
      const [startStr, endStr] = clickedElement.split(' - ');
      setHeatmapDailyDateRangeLabel(clickedElement);

      const heatmapStartDate = new Date(startStr + ' ' + new Date().getFullYear())
      const heatmapEndDate = new Date(endStr + ' ' + new Date().getFullYear())

      setIsDaily(true);
      setSeries(
        createDailySeries(data, heatmapStartDate, heatmapEndDate, heatmapColorRange[0], heatmapColorRange[1])
      );
    } else {
      setIsDaily(false);
      setSeries(createWeeklySeries(data, startDate, endDate));
    }
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <MemoizedHeatmap
        measurement={measurement}
        data={data}
        series={series}
        colorRange={heatmapColorRange}
        isDaily={isDaily}
        onClick={onChangeView}
        heatmapDailyDateRangeLabel={heatmapDailyDateRangeLabel}
        friendlyName={friendlyName}
        palette={colorProgression} />
      <Slider
        value={sliderRange}
        step={0.005}
        onChange={(_, value) => {
          setSliderRange(value as [number, number]);
        }}
        onChangeCommitted={() => setHeatmapColorRange(sliderRange)}
        min={Number(min.toFixed(2))}
        max={Number(max.toFixed(2))}
        sx={{
          mx: 'auto',
          width: '50%',
          [`& .${sliderClasses.rail}`]: {
            backgroundImage: `linear-gradient(to left, ${colorProgression[0]}, ${colorProgression[4]})`,
          },
          [`& .${sliderClasses.track}`]: {
            backgroundImage: `linear-gradient(to left, ${colorProgression[0]}, ${colorProgression[4]})`,
          },
          [`& .${sliderClasses.thumb}`]: {
            backgroundColor: 'white',
          },
          [`& .${sliderClasses.mark}`]: {
            backgroundColor: colorProgression[4],
          },
          [`& .${sliderClasses.markActive}`]: {
            backgroundColor: colorProgression[0],
          },
          [`& .${sliderClasses.markLabel}`]: {
            color: colorProgression[4],
          },
          color: colorProgression[0],
        }}
        valueLabelDisplay="auto"
        aria-labelledby="range-slider"
        marks={[
          { value: sliderRange[0], label: <span style={{ color: "#000" }}>{sliderRange[0].toFixed(2)}</span> },
          { value: sliderRange[1], label: <span style={{ color: "#000" }}>{sliderRange[1].toFixed(2)}</span> },
        ]}
      />
    </div>
  );
};