import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from '@cloudscape-design/components';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { userManagerAPI } from 'api';
import DeviceSelect from 'components/device-select';
import {
    API_URL_PATH_GROUP_LIST,
    URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST,
} from 'constants/urls';
import { useAlertRulesManagerContext } from 'providers/AlertRulesManagerProvider';
import { Asset, DeviceGroup } from 'types/custom';
import useMutation from 'hooks/useMutation';

type CreateDeviceGroupType = {
    setShowCreateDeviceGroupModal?: (state: boolean) => void;
    setShowCreateAlertModal?: (state: boolean) => void;
    isDeviceSelectDisabled?: boolean;
}

const CreateDeviceGroup = ({
    setShowCreateDeviceGroupModal,
    setShowCreateAlertModal,
    isDeviceSelectDisabled,
}: CreateDeviceGroupType) => {
    const { selectedDevices, setSelectedGroup } = useAlertRulesManagerContext();
    const [groupId, setGroupId] = useState('');
    const [disableFormFields, setDisableFormFields] = useState(false);
    const [friendlyName, setFriendlyName] = useState('');
    const [description, setDescription] = useState('');
    const navigate = useNavigate();

    const [selectedAssets, setSelectedAssets] = useState<
        readonly OptionDefinition[]
    >([]);

    useEffect(() => {
        if (selectedDevices) {
            setSelectedAssets(selectedDevices.map((device: Asset) => ({
                label: device.friendlyName,
                description: device.description,
                labelTag: device.name,
                value: device.name,
            })));
        }
    }, [])

    const {
        mutateAsync: createDeviceGroup,
        status: createDeviceGroupStatus,
        error: createDeviceGroupError,
        isPending: createDeviceGroupLoading,
        data: createDeviceGroupResponse,
    } = useMutation<DeviceGroup, {
        groupId: string;
        description: string;
        friendlyName: string;
        assets: { assetCategory: string; assetId: string }[];
    }>({
        api: userManagerAPI,
        method: 'POST',
        url: API_URL_PATH_GROUP_LIST,
        notifications: [{
            type: 'success',
            content: (setShowCreateDeviceGroupModal && setShowCreateAlertModal) ? 'Successfully created new group' : `Successfully created Device Group ${groupId}`,            
        }],
    });

    useEffect(() => {
        if (createDeviceGroupStatus === 'success') {
            if (setShowCreateDeviceGroupModal && setShowCreateAlertModal) {
                setShowCreateDeviceGroupModal(false)
                setShowCreateAlertModal(true)
                if (createDeviceGroupResponse) {
                    setSelectedGroup(createDeviceGroupResponse.data as DeviceGroup);
                }
                setDisableFormFields(false);
            }
            else {
                navigate(URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST);
                setDisableFormFields(false);
            }
        }
    }, [createDeviceGroupStatus]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        await createDeviceGroup({
            groupId,
            description,
            friendlyName,
            assets: selectedAssets?.map((asset) => ({
                assetCategory: 'device',
                assetId: asset.value || '',
            })) || [],
        });
    };

    return (
      <Form
            actions={
              <SpaceBetween direction='horizontal' size='xs'>
                <Button
                        formAction='none'
                        variant='link'
                        onClick={() => {
                            if (setShowCreateDeviceGroupModal) {
                                setShowCreateDeviceGroupModal(false)
                            } else {
                                navigate('/device-group-manager/list', {})
                            }
                        }
                        }
                    >
                  Cancel
                </Button>
                <Button
                        variant='primary'
                        loading={createDeviceGroupLoading}
                        onClick={handleSubmit}
                    >
                  Submit
                </Button>
              </SpaceBetween>
            }
            header={!setShowCreateDeviceGroupModal &&
              <Header variant='h2' description=''>
                Create a Device Group
              </Header>
            }
            errorText={createDeviceGroupError?.message}
        >
        <SpaceBetween direction='vertical' size='l'>
          <FormField label='Group Name'>
            <Input
                        disabled={disableFormFields}
                        value={groupId}
                        onChange={(event) => setGroupId(event.detail.value)}
                    />
          </FormField>
          <FormField
                    label={
                      <span>
                        Description <i>- optional</i>{' '}
                      </span>
                    }
                >
            <Input
                        disabled={disableFormFields}
                        value={description}
                        onChange={(event) =>
                            setDescription(event.detail.value)
                        }
                    />
          </FormField>
          <FormField label='Friendly Name'>
            <Input
                        disabled={disableFormFields}
                        value={friendlyName}
                        onChange={(event) =>
                            setFriendlyName(event.detail.value)
                        }
                    />
          </FormField>

          <DeviceSelect
                    disabled={disableFormFields || !!isDeviceSelectDisabled}
                    selectedAssets={selectedAssets}
                    setSelectedAssets={setSelectedAssets}
                />
        </SpaceBetween>
      </Form>
    );
};

export default CreateDeviceGroup;
