import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Spinner,
} from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

import { incidentManagerAPI } from 'api';
import DeleteModal from 'components/delete-modal';
import { API_URL_PATH_IM_PLANS } from 'constants/urls';
import TransferList from 'components/incident-manager/TransferList';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';
import useMutation from 'hooks/useMutation';
import { EscalationPlan, EscalationPlanDto, NotificationGroup, StageDto } from 'types/custom';

const PlansView = () => {
    const { selectedPlan, setSelectedPlan, plansLoading, allGroups, plansRefetch, allPlans } =
        useNotificationManagerContext();
    const [planName, setPlanName] = useState('');
    const [planError, setPlanError] = useState('');
    const [description, setDescription] = useState('');
    const [stageDuration, setStageDuration] = useState<string>('');
    const [flowType, setFlowType] = useState('update');
    const [disableFormFields, setDisableFormFields] = useState(false);
    const [stages, setStages] = useState<any>([]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDeleteDiscard = () => setShowDeleteModal(false);

    useEffect(() => {
        if (!plansLoading && !allPlans) {
            setFlowType('create');
        }
    }, [allPlans, plansLoading]);

    useEffect(() => {
        if (flowType === 'update') {
            setPlanName(selectedPlan?.name || '');
            setDescription(selectedPlan?.description || '');
        }
        setStageDuration('');
    }, [selectedPlan, flowType]);

    const validatePlanName = () => {
        const groupNameRegExp = /^[a-z0-9_\-](.|\s)*$/;
        const isValid = groupNameRegExp.test(planName);

        if (planName && isValid) {
            setPlanError('');
        } else {
            setPlanError('Enter a valid plan name');
        }

        return isValid;
    };

    const {
        mutateAsync: createPlan,
        error: createPlanError,
        isPending: createPlanLoading,
        status: createPlanStatus,
        data: createPlanResponse,
    } = useMutation<EscalationPlan, Partial<EscalationPlanDto>>({
        api: incidentManagerAPI,
        method: 'POST',
        url: API_URL_PATH_IM_PLANS,
    });

    const {
        mutateAsync: updatePlan,
        error: updatePlanError,
        isPending: updatePlanLoading,
        status: updatePlanStatus,
        data: updatePlanResponse,
    } = useMutation<EscalationPlan, Partial<EscalationPlanDto>>({
        api: incidentManagerAPI,
        method: 'PATCH',
        url: `${API_URL_PATH_IM_PLANS}/${selectedPlan?.name}`,
    });

    const {
        mutateAsync: deletePlan,
        isPending: deletePlanLoading,
    } = useMutation<string, {}>({
        api: incidentManagerAPI,
        method: 'DELETE',
        url: `${API_URL_PATH_IM_PLANS}/${selectedPlan?.name}`,
    });

    const {
        mutateAsync: activatePlan,
        error: activatePlanError,
        isPending: activatePlanLoading,
        status: activatePlanStatus,
        data: activatePlanResponse,
    } = useMutation<NotificationGroup, {
        description: string;
        active: boolean;
    }>({
        api: incidentManagerAPI,
        method: 'PATCH',
        url: `${API_URL_PATH_IM_PLANS}/${selectedPlan?.name}`,
    });


    const handleCreateAction = (event: any) => {
        event.preventDefault();
        setFlowType('create');
        setPlanName('');
        setDescription('');
    };

    const handleDeleteAction = (event: any) => {
        event.preventDefault();
        setShowDeleteModal(true);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const planNameValidation = validatePlanName();
        if (!planNameValidation) return;

        setDisableFormFields(true);

        if (flowType === 'update') {
            await updatePlan({
                description,
                stages,
            });
        } else {
            await createPlan({
                name: planName,
                description,
                stages,
            });
        }
    };

    useEffect(() => {
        setDisableFormFields(false);
        if (
            updatePlanStatus === 'success' ||
            createPlanStatus === 'success' ||
            activatePlanStatus === 'success'
        ) {
            plansRefetch();
            setFlowType('update');
        } else if (
            updatePlanStatus === 'error' ||
            createPlanStatus === 'error' ||
            activatePlanStatus === 'error'
        ) {
            setDisableFormFields(false);
        }
    }, [
        createPlanStatus,
        createPlanResponse,
        updatePlanStatus,
        updatePlanResponse,
        activatePlanStatus,
        activatePlanResponse,
    ]);

    const onDeleteConfirm = async () => {
        await deletePlan({})
        plansRefetch();
        setFlowType('update');
        setSelectedPlan(null);
        setShowDeleteModal(false);
    };

    return (
      <div style={{ minHeight: '700px' }}>
        {plansLoading && flowType === 'update' ? (
          <Spinner />
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <Form
                            actions={
                              <SpaceBetween direction='horizontal' size='xs'>
                                {flowType === 'create' && (
                                  <Button
                                            formAction='none'
                                            variant='link'
                                            onClick={() => {
                                                setFlowType('update');
                                            }}
                                        >
                                    Cancel
                                  </Button>
                                    )}
                                <Button
                                        variant='primary'
                                        loading={
                                            createPlanLoading ||
                                        updatePlanLoading
                                        }
                                    >
                                  Submit
                                </Button>
                              </SpaceBetween>
                            }
                            header={
                              <Header
                                    variant='h3'
                                    actions={
                                        flowType !== 'create' && (
                                          <SpaceBetween
                                                direction='horizontal'
                                                size='xs'
                                            >
                                            <Button
                                                    onClick={async () => await activatePlan({
                                                        description: selectedPlan?.description || '',
                                                        active: !selectedPlan?.active,
                                                    })}
                                                    loading={activatePlanLoading}
                                                    disabled={!selectedPlan}
                                                >
                                              {selectedPlan?.active ===
                                                        true
                                                        ? 'Disable'
                                                        : 'Activate'}
                                            </Button>
                                            <Button
                                                    onClick={handleDeleteAction}
                                                    loading={deletePlanLoading}
                                                    disabled={!selectedPlan}
                                                >
                                              Delete
                                            </Button>
                                            <Button
                                                    variant='primary'
                                                    onClick={handleCreateAction}
                                                    loading={createPlanLoading}
                                                >
                                              Create new
                                            </Button>
                                          </SpaceBetween>
                                    )
                                    }
                                >
                                {flowType === 'update'
                                        ? 'Update'
                                        : 'Create'}{' '}
                                Escalation Plan
                              </Header>
                            }
                            errorText={
                                createPlanError?.message ||
                                updatePlanError?.message ||
                                activatePlanError?.message
                            }
                        >
                    <SpaceBetween direction='vertical' size='l'>
                      <FormField label='Name' errorText={planError}>
                        <Input
                                        disabled={
                                            plansLoading ||
                                            disableFormFields ||
                                            flowType === 'update'
                                        }
                                        value={planName}
                                        onChange={(event) =>
                                            setPlanName(event.detail.value)
                                        }
                                        onBlur={validatePlanName}
                                    />
                      </FormField>

                      <FormField label='Description'>
                        <Input
                                        disabled={disableFormFields}
                                        value={description}
                                        onChange={(event) =>
                                            setDescription(event.detail.value)
                                        }
                                    />
                      </FormField>

                      <FormField
                                    label='Stage Duration (minutes)'
                                    description='Select notification group(s) from below choices and enter the time'
                                    constraintText='On the last stage, 0 will end the escalation'
                                >
                        <Input
                                        disabled={
                                            plansLoading || disableFormFields
                                        }
                                        value={stageDuration}
                                        onChange={(event) =>
                                            setStageDuration(
                                                event.detail.value
                                        )
                                        }
                                    />
                      </FormField>

                      <FormField
                                    label='Notification group(s)'
                                    description='Escalation will be stopped when the incident becomes acknowledged'
                                />
                      <TransferList
                                    allItems={allGroups}
                                    selectedItems={selectedPlan}
                                    itemType='plans'
                                    stageDuration={stageDuration}
                                    setStageDuration={setStageDuration}
                                    flowType={flowType}
                                    setChosen={setStages}
                                />
                    </SpaceBetween>
                    <br></br>
                  </Form>
                </form>

                <DeleteModal
                        visible={showDeleteModal}
                        onDiscard={onDeleteDiscard}
                        onDelete={onDeleteConfirm}
                        itemName={[selectedPlan]}
                        itemCount={1}
                        moduleName='Escalation Plan'
                    />
              </>
            )}
      </div>
    );
};

export default PlansView;
