import { keepPreviousData, useQueries } from "@tanstack/react-query";
import { useMemo } from "react";

import { getChartData } from "../fetchers/device-manager";
import { useDashboardContext } from "../../providers/DashboardProvider";
import { DeviceTelemetry } from "../../components/device-chart/types";
import getDateTimeRange from "../../components/date-time-picker/utils";

function useGetDeviceChart({ measurement, deviceIds }: {
    measurement: string;
    deviceIds: string[];
}) {
  const { chartDevices, dateTimeRange } = useDashboardContext();
  const { from, to } = useMemo(() => getDateTimeRange(dateTimeRange), [dateTimeRange]);

  const queries = useQueries(
    {
      queries: deviceIds.map((device) => ({
        queryKey: ['device-chart', { device, measurement, from, to }],
        queryFn: () => getChartData({ queryKey: [{ device, measurement, from, to }] }),
        placeholderData: keepPreviousData
      })),
    }
  );

  return {
    data: queries.map((query) => query?.data?.map(([x, y, device]: DeviceTelemetry) => {
      const value = parseFloat(y).toFixed(2);
      const deviceColor = chartDevices.find((chartDevice) => chartDevice.id === device)?.color
      const deviceDisplayName = chartDevices.find((chartDevice) => chartDevice.id === device)?.name || device

      if(!deviceColor) return undefined;

      return [x, value, deviceColor, deviceDisplayName];
    }) || []),
    isLoading: queries.some((query) => query.isLoading),
    isError: queries.some((query) => query.isError),
  }
}

export default useGetDeviceChart;
