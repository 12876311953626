import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from 'components/common/layout';
import {
  URL_PATH_LOGIN,
  URL_PATH_DASHBOARD,
  URL_PATH_UNAUTHORIZED,
  URL_PATH_USER_MANAGER,
  URL_PATH_DEVICE_MANAGER,
  URL_FULL_PATH_INCIDENT_MANAGER,
  URL_FULL_PATH_USER_MANAGER_ROLES,
  URL_FULL_PATH_DEVICE_MANAGER_LIST,
  URL_FULL_PATH_DEVICE_GROUP_MANAGER,
  URL_FULL_PATH_NOTIFICATION_MANAGER,
  URL_PATH_DEVICE_MANAGER_INSTALL_DEVICE,
  URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST,
  URL_FULL_PATH_NOTIFICATION_MANAGER_VIEW,
  URL_FULL_PATH_DEVICE_GROUP_MANAGER_CREATE,
  URL_PATH_ALERT_RULES_MANAGER,
} from 'constants/urls';
import Login from 'pages/login';
import Dashboard from 'pages/dashboard';
import UserManager from 'pages/role-manager';
import Unauthorized from 'pages/unauthorized';
import DeviceManager from 'pages/device-manager';
import RequireAuth from 'setup/auth/RequireAuth';
import NotificationManager from 'pages/notification-manager';
import IncidentManager from 'pages/incident-manager';
import SensorGroupManager from 'pages/device-group-manager';
import RolesListPage from 'pages/role-manager/RolesListPage';
import CreateRolePage from 'pages/role-manager/CreateRolePage';
import DeviceListPage from 'pages/device-manager/DeviceListPage';
import InstallDevicePage from 'pages/device-manager/InstallDevicePage';
import NotificationManagerPage from 'pages/notification-manager/NotificationManagerPage';
import SensorGroupListPage from 'pages/device-group-manager/DeviceGroupListPage';
import CreateDeviceGroupPage from 'pages/device-group-manager/CreateDeviceGroupPage';
import IncidentManagerPage from 'pages/incident-manager/incident-manager-page';
import AlertRulesManager from 'pages/alert-rules-manager';
import AlertRulesManagerPage from 'pages/alert-rules-manager/alert-rules-manager-page';

const RoutesManager = () => {
  return (
    <Routes>
      {/* Login route */}
      <Route path={URL_PATH_LOGIN} element={<Login />} />

      {/* Redirects */}
      <Route
        index
        element={<Navigate to={URL_PATH_DASHBOARD} replace />}
      />

      <Route element={<RequireAuth allowedRoles={[]} />}>
        <Route path='/' element={<Layout />}>
          {/* User manager routes */}
          <Route
            path={URL_PATH_USER_MANAGER}
            element={<UserManager />}
          >
            <Route
              element={
                <RequireAuth allowedRoles={['read:roles']} />
              }
            >
              <Route
                path={URL_FULL_PATH_USER_MANAGER_ROLES}
                element={<RolesListPage />}
              />
            </Route>
            <Route
              element={
                <RequireAuth allowedRoles={['create:roles']} />
              }
            >
              <Route
                path='roles/create'
                element={<CreateRolePage />}
              />
            </Route>
          </Route>

          {/* Device manager routes */}
          <Route
            path={URL_PATH_DEVICE_MANAGER}
            element={<DeviceManager />}
          >
            <Route
              element={
                <RequireAuth allowedRoles={['read:sensors']} />
              }
            >
              <Route
                path={URL_FULL_PATH_DEVICE_MANAGER_LIST}
                element={<DeviceListPage />}
              />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={['install:sensors']}
                />
              }
            >
              <Route
                path={URL_PATH_DEVICE_MANAGER_INSTALL_DEVICE}
                element={<InstallDevicePage />}
              />
            </Route>
            <Route
              path='*'
              element={
                <Navigate
                  to={URL_FULL_PATH_DEVICE_MANAGER_LIST}
                  replace
                />
              }
            />
          </Route>

          {/* Dashboard routes */}
          <Route
            element={
              <RequireAuth allowedRoles={['read:telemetry']} />
            }
          >
            <Route
              path={URL_PATH_DASHBOARD}
              element={<Dashboard />}
            ></Route>
          </Route>

          {/* Notification manager routes */}
          <Route
            path={URL_FULL_PATH_NOTIFICATION_MANAGER}
            element={<NotificationManager />}
          >
            <Route
              element={
                <RequireAuth allowedRoles={['read:contacts']} />
              }
            >
              <Route
                path={URL_FULL_PATH_NOTIFICATION_MANAGER_VIEW}
                element={<NotificationManagerPage />}
              />
            </Route>
          </Route>

          {/* Device Group manager routes */}
          <Route
            path={URL_FULL_PATH_DEVICE_GROUP_MANAGER}
            element={<SensorGroupManager />}
          >
            <Route
              element={
                <RequireAuth
                  allowedRoles={['read:devicegroup']}
                />
              }
            >
              <Route
                path={URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST}
                element={<SensorGroupListPage />}
              />
            </Route>

            <Route
              element={
                <RequireAuth
                  allowedRoles={['create:devicegroup']}
                />
              }
            >
              <Route
                path={URL_FULL_PATH_DEVICE_GROUP_MANAGER_CREATE}
                element={<CreateDeviceGroupPage />}
              />
            </Route>
          </Route>

          {/* Alert Rules manager routes */}
          <Route
            element={
              <AlertRulesManager />
            }
          >
            <Route element={
              <RequireAuth allowedRoles={['read:sensors']} />
            }>
              <Route
                path={URL_PATH_ALERT_RULES_MANAGER}
                element={<AlertRulesManagerPage />}
              />
            </Route>
          </Route>

          {/* Incident manager routes */}
          <Route
            path={URL_FULL_PATH_INCIDENT_MANAGER}
            element={<IncidentManager />}>
            <Route
              path={URL_FULL_PATH_INCIDENT_MANAGER}
              element={<IncidentManagerPage />} />
          </Route>

          {/* Unauthorized */}
          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path={URL_PATH_UNAUTHORIZED}
              element={<Unauthorized />}
            />
          </Route>

          {/* Activate contact */}
          {/* <Route element={<RequireAuth allowedRoles={[]} />}>
                        <Route
                            path={URL_PATH_ACTIVATE_CONTACT}
                            element={<ActivateContact />}
                        />
                    </Route> */}

          {/* <Route
                        path='/'
                        element={
                            <Navigate to={URL_PATH_USER_MANAGER} replace />
                        }
                    /> */}
        </Route>
      </Route>

      <Route
        path='*'
        element={<Navigate to={URL_PATH_DASHBOARD} replace />}
      />
    </Routes>
  );
};

export default RoutesManager;
