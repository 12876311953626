interface RuntimeConfig {
    envTitle: string;
    deviceManagerApiUrl: string;
    userManagerApiUrl: string;
    locationProxyApiUrl: string;
    incidentManagerApiUrl: string;
    cognitoUserPoolId: string;
    cognitoClientId: string;
    cognitoRegion: string;
    cognitoOAuthDomain: string;
    cognitoSignInProvider: string;
    loginRedirectUrls: string;
    logoutRedirectUrls: string;
}

declare global {
    interface Window { runtimeConfig: RuntimeConfig; }
}

if (!window['runtimeConfig']) {
  if (process.env.REACT_APP_RUNTIME_CONFIG) {
    window['runtimeConfig'] = JSON.parse(process.env.REACT_APP_RUNTIME_CONFIG);
  } else {
    document.body.innerHTML = 'Unexpected error: Missing runtime configuration.';
    throw new Error('Missing runtime configuration.');
  }
}

export {};