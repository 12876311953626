import { Alert, Badge, Button, Checkbox, Header, SpaceBetween, Spinner } from "@cloudscape-design/components";
import LensIcon from '@mui/icons-material/Lens';
import { useEffect } from "react";

import { useDashboardContext } from "providers/DashboardProvider";
import { AssetState, StateAlertCode } from "types/custom";
import StatePopover from "components/state-popover/StatePopover";

import { snakeCaseToNormalCase } from "./utils";
import useGetDevice from "../../api/hooks/use-get-device";


type DeviceCardProps = {
  deviceId: string;
  deviceColor: string;
};

const DeviceCard = ({ deviceId, deviceColor }: DeviceCardProps) => {
  const { selectedMeasurements, setSelectedMeasurements, setChartDevices, chartDevices } = useDashboardContext();

  const { data: deviceData, isLoading: isDeviceLoading } = useGetDevice({ id: deviceId });

  const handleMeasurementChange = (measurement: string) => {
    const selectedDevices = selectedMeasurements.get(measurement) || [];
    const newSelectedDevices = selectedDevices.includes(deviceId)
      ? selectedDevices.filter((id) => id !== deviceId)
      : [...selectedDevices, deviceId];

    setSelectedMeasurements(new Map(selectedMeasurements.set(measurement, newSelectedDevices)));
  };

  useEffect(() => {
    if (deviceData?.state) {
      const measurement = Object.keys(deviceData.state)?.[0];
      if (measurement) handleMeasurementChange(measurement);
    }
  }, [deviceData]);

  const handleRemoveDevice = () => {
    setChartDevices(chartDevices.filter((device) => device.id !== deviceId));
    const newSelectedMeasurements = new Map(selectedMeasurements);
    newSelectedMeasurements.forEach((value, key) => {
      newSelectedMeasurements.set(key, value.filter((id) => id !== deviceId));
    });
    setSelectedMeasurements(newSelectedMeasurements);
  };

  const colorForBadge = (state: AssetState) => {
    switch (state.alertCode) {
      case StateAlertCode.LL:
      case StateAlertCode.L:
      case StateAlertCode.H:
      case StateAlertCode.HH:
        return "red";
      case StateAlertCode.LL_PENDING:
      case StateAlertCode.L_PENDING:
      case StateAlertCode.H_PENDING:
      case StateAlertCode.HH_PENDING:
      case StateAlertCode.N_PENDING:
        return "blue";
      case StateAlertCode.N:
        return "green";
      case StateAlertCode.U:
      default:
        return "grey";
    }
  }

  return (
    <div
      style={{
        border: "1px solid #c1ccda",
        flexShrink: 0,
        width: isDeviceLoading ? "200px" : 'auto',
        minHeight: "200px",
        margin: "0 4px 0 4px",
        padding: "8px 10px 8px 10px",
        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.2)",
        borderRadius: "4px",
      }}
    >
      {isDeviceLoading && <Spinner />}
      {deviceData && (
        <>
          <Header variant="h3" actions={<Button onClick={handleRemoveDevice} iconAlign="right" iconName="close" variant="inline-icon" />} headingTagOverride="h4">
            <LensIcon style={{ color: deviceColor, fontSize: "18px", verticalAlign: "middle" }} /> <span style={{ fontSize: "15px" }}>{deviceData.friendlyName}</span>
          </Header>
          <span style={{ color: "#c1ccda", paddingBottom: "16px" }}>{deviceData.name}</span>
          {!deviceData.state && <Alert>No telemetries available.</Alert>}
          {deviceData.state && Object.keys(deviceData.state).length === 0 && <Alert>No telemetries data available.</Alert>}
          {deviceData.state && Object.entries(deviceData.state).map(([measurement, state]) => (
            <SpaceBetween size="s" key={`${deviceId}-${measurement}`} direction="horizontal">
              <Checkbox
                name={measurement + deviceId}
                key={`${deviceId}-${measurement}`}
                checked={!!selectedMeasurements.get(measurement)?.includes(deviceId)}
                onChange={() => handleMeasurementChange(measurement)}
              >
                {snakeCaseToNormalCase(measurement)} <Badge color={colorForBadge(state)}><i>
                  {parseFloat(String(state.value)).toFixed(2)}
                </i></Badge>
              </Checkbox>
              <StatePopover measurement={measurement} state={state} customTrigger={true}>
                <Button iconName="ellipsis" variant="inline-icon"/>
              </StatePopover>
            </SpaceBetween>
          ))}
        </>
      )}
    </div>
  );
};

export default DeviceCard;
