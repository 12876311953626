import { Alert, Spinner } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';

import ListItemComp from 'components/incident-manager/ListItem';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

const PlansList = () => {
    const { allPlans, setSelectedPlan, plansLoading, selectedPlan } =
        useNotificationManagerContext();

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (allPlans) {
            const index = selectedPlan && allPlans.findIndex((plan) => plan.name === selectedPlan.name);
            if (selectedPlan && index) {
                setSelectedPlan(selectedPlan);
                setSelectedIndex(index);
            } else {
                setSelectedPlan(allPlans[0]);
                setSelectedIndex(0);
            }
        }
    }, [allPlans]);

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
    };

    return (
      <div style={{ minHeight: '660px' }}>
        {plansLoading && <Spinner />}
        {!plansLoading && !allPlans?.length && (
          <Alert>No escalation plans found</Alert>
            )}

        <ListItemComp
                allItems={allPlans}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                setSelectedItem={setSelectedPlan}
                module='plans'
            />
      </div>
    );
};

export default PlansList;
