import { useEffect, useState } from 'react';
import { Select, TimeInput } from '@cloudscape-design/components';

import { convertToTwelveHourFormat, convertToTwentyFourHourFormat } from 'components/date-time-picker/utils';

type TimePickerProps = {
  use24Hour: boolean;
  hour: string;
  loading: boolean;
  onBlur: () => void;
  onChange: (value: string) => void;
  onFocus: () => void;
};

const TimePicker = ({ hour, use24Hour, loading, onBlur, onChange, onFocus }: TimePickerProps) => {
  const [displayHour, setDisplayHour] = useState(hour);
  const [postFix, setPostFix] = useState(hour >= '12' ? 'PM' : 'AM');

  useEffect(() => {
    if (!hour || hour.trim() === '') {
      setDisplayHour('');
      setPostFix('AM');
      return;
    }
    if (use24Hour) {
      setDisplayHour(hour);
    } else {
      const [twelveHour, amPm] = convertToTwelveHourFormat(hour).split(' ');
      setDisplayHour(twelveHour);
      setPostFix(amPm as 'AM' | 'PM');
    }
  }, [use24Hour, hour]);

  const handlePostFixChange = (newPostFix: 'AM' | 'PM') => {
    setPostFix(newPostFix);
    if (!use24Hour) {
      const updatedHour = convertToTwentyFourHourFormat(displayHour, newPostFix);
      onChange(updatedHour);
    }
  };

  const handleHourChange = (newHour: string) => {
    setDisplayHour(newHour);
    if (use24Hour) {
      onChange(newHour);
    } else {
      const updatedHour = convertToTwentyFourHourFormat(newHour, postFix);
      onChange(updatedHour);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <TimeInput
        value={displayHour}
        disabled={loading}
        use24Hour={use24Hour}
        onChange={({ detail }) => handleHourChange(detail.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        format="hh"
        placeholder="hh"
      />
      {!use24Hour && (
        <Select
          selectedOption={{ label: postFix, value: postFix }}
          disabled={loading}
          onChange={({ detail }) => handlePostFixChange(detail.selectedOption.value as 'AM' | 'PM')}
          options={[
            { label: 'AM', value: 'AM' },
            { label: 'PM', value: 'PM' },
          ]}
        />
      )}
    </div>
  );
};

export default TimePicker;
