import {
    Button,
    Form,
    FormField,
    Header,
    Input,
    SpaceBetween,
    Spinner,
} from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';

import { incidentManagerAPI } from 'api';
import DeleteModal from 'components/delete-modal';
import { API_URL_PATH_IM_GROUPS } from 'constants/urls';
import TransferList from 'components/incident-manager/TransferList';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';
import useMutation from 'hooks/useMutation';
import { NotificationGroup } from 'types/custom';

const GroupsView = () => {
    const { selectedGroup, allContacts, groupsRefetch, groupsLoading, allGroups } =
        useNotificationManagerContext();
    const [groupName, setGroupName] = useState('');
    const [groupNameError, setGroupNameError] = useState('');
    const [description, setDescription] = useState('');
    const [flowType, setFlowType] = useState('update');
    const [disableFormFields, setDisableFormFields] = useState(false);
    const [contactsObj, setContactsObj] = useState([]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const onDeleteDiscard = () => setShowDeleteModal(false);

    useEffect(() => {
        if (!allGroups || !allGroups?.length) {
            setFlowType('create');
        }
    }, [allGroups]);

    useEffect(() => {
        if (flowType === 'update') {
            setGroupName(selectedGroup?.name || '');
            setDescription(selectedGroup?.description || '');
        }
    }, [selectedGroup, flowType]);

    useEffect(() => {
        setGroupName(selectedGroup?.name || '');
    }, [selectedGroup]);

    const validateGroupName = () => {
        const groupNameRegExp = /^[a-z0-9_-](.|\s)*$/;
        const isValid = groupNameRegExp.test(groupName);

        if (groupName && isValid) {
            setGroupNameError('');
        } else {
            setGroupNameError('Enter a valid group name');
        }

        return isValid;
    };

    const {
        mutateAsync: createGroup,
        error: createGroupError,
        isPending: createGroupLoading,
        status: createGroupStatus,
        data: createGroupResponse,
    } = useMutation<NotificationGroup, {
        name: string;
        description: string;
        contacts: any[];
    }>({
        api: incidentManagerAPI,
        method: 'POST',
        url: API_URL_PATH_IM_GROUPS,
    });

    const {
        mutateAsync: updateGroup,
        error: updateGroupError,
        isPending: updateGroupLoading,
        status: updateGroupStatus,
        data: updateGroupResponse,
    } = useMutation<NotificationGroup, {
        description: string;
        contacts: any[];
    }>({
        api: incidentManagerAPI,
        method: 'PATCH',
        url: `${API_URL_PATH_IM_GROUPS}/${selectedGroup?.name}`,
    });

    const {
        mutateAsync: deleteGroup,
        error: deleteGroupError,
        isPending: deleteGroupLoading,
    } = useMutation<string, {}>({
        api: incidentManagerAPI,
        method: 'DELETE',
        url: `${API_URL_PATH_IM_GROUPS}/${selectedGroup?.name}`,
    });

    const handleCreateAction = (event: any) => {
        event.preventDefault();
        setFlowType('create');
        setGroupName('');
        setDescription('');
    };

    const handleDeleteAction = (event: any) => {
        event.preventDefault();
        setShowDeleteModal(true);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const groupNameValidation = validateGroupName();
        if (!groupNameValidation) return;

        setDisableFormFields(true);

        if (flowType === 'update') {
            await updateGroup({
                description,
                contacts: contactsObj,
            });
        } else {
            await createGroup({
                name: groupName,
                description,
                contacts: contactsObj,
            });
        }
    };

    useEffect(() => {
        setDisableFormFields(false);
        if (
            updateGroupStatus === 'success' ||
            createGroupStatus === 'success'
            // deletePlanStatus === 200
        ) {
            groupsRefetch();
            setFlowType('update');
        } else if (
            updateGroupStatus === 'error' ||
            createGroupStatus === 'error'
            // deletePlanStatus > 201
        ) {
            setDisableFormFields(false);
        }
    }, [
        createGroupStatus,
        createGroupResponse,
        updateGroupStatus,
        updateGroupResponse,
    ]);

    const onDeleteConfirm = async () => {
        await deleteGroup({})
        groupsRefetch();
        setFlowType('update');
        setShowDeleteModal(false);
    };

    return (
      <>
        {groupsLoading && flowType === 'update' ? (
          <Spinner />
            ) : (
              <>
                <form onSubmit={handleSubmit}>
                  <Form
                            actions={
                              <SpaceBetween direction='horizontal' size='xs'>
                                {flowType === 'create' && (
                                  <Button
                                            formAction='none'
                                            variant='link'
                                            onClick={() => {
                                                setFlowType('update');
                                            }}
                                        >
                                    Cancel
                                  </Button>
                                    )}
                                <Button
                                        variant='primary'
                                        loading={
                                            createGroupLoading ||
                                        updateGroupLoading
                                        }
                                    >
                                  Save
                                </Button>
                              </SpaceBetween>
                            }
                            header={
                              <Header
                                    variant='h3'
                                    actions={
                                        flowType !== 'create' && (
                                          <SpaceBetween
                                                direction='horizontal'
                                                size='xs'
                                            >
                                            <Button
                                                    variant='normal'
                                                    disabled={!selectedGroup}
                                                    onClick={handleDeleteAction}
                                                    loading={deleteGroupLoading}
                                                >
                                              Delete
                                            </Button>
                                            <Button
                                                    variant='primary'
                                                    onClick={handleCreateAction}
                                                >
                                              Create new
                                            </Button>
                                          </SpaceBetween>
                                    )
                                    }
                                >
                                {flowType === 'update'
                                        ? 'Update'
                                        : 'Create'}{' '}
                                Notification Group
                              </Header>
                            }
                            errorText={createGroupError?.message || updateGroupError?.message || deleteGroupError?.message}
                        >
                    <SpaceBetween direction='vertical' size='l'>
                      <FormField
                                    label='Name'
                                    errorText={groupNameError}
                                >
                        <Input
                                        disabled={
                                            disableFormFields ||
                                            flowType === 'update'
                                        }
                                        value={groupName}
                                        onChange={(event) =>
                                            setGroupName(event.detail.value)
                                        }
                                        onBlur={validateGroupName}
                                    />
                      </FormField>

                      <FormField label='Description'>
                        <Input
                                        disabled={disableFormFields}
                                        value={description}
                                        onChange={(event) =>
                                            setDescription(event.detail.value)
                                        }
                                    />
                      </FormField>

                      <FormField label='Contacts' />
                      <TransferList
                                    allItems={allContacts}
                                    selectedItems={selectedGroup}
                                    itemType='groups'
                                    flowType={flowType}
                                    setChosen={setContactsObj}
                                />
                    </SpaceBetween>
                  </Form>
                </form>

                <DeleteModal
                        visible={showDeleteModal}
                        onDiscard={onDeleteDiscard}
                        onDelete={onDeleteConfirm}
                        itemName={[selectedGroup]}
                        itemCount={1}
                        moduleName='Notification Group'
                    />
              </>
            )}
      </>
    );
};

export default GroupsView;
