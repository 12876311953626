import {
    Form,
    Input,
    Header,
    Button,
    Select,
    FormField,
    SpaceBetween,
} from '@cloudscape-design/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useState } from 'react';

import useMutation from 'hooks/useMutation';
import RolePicker from 'components/role-picker/RolePicker';
import DeviceGroups from 'components/device-groups';
import { UserRolesProps } from 'types/custom';
import Location from 'components/location';
import { userManagerAPI } from 'api';
import {
    URL_FULL_PATH_USER_MANAGER_ROLES,
    API_URL_PATH_UM_ROLES,
} from 'constants/urls';

import { CreateRoleFormValues, CreateRoleDto, privilegeOptions, validateCreateRoleFormValues } from './utils';

type CreateRoleComponentState = {
    state: {
        action: 'create' | 'edit';
        role: UserRolesProps;
    }
};

const CreateRole = () => {
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const navigate = useNavigate();
    const { state }: CreateRoleComponentState = useLocation();
    const isEditMode = state?.action === 'edit';
    const role = isEditMode ? state.role : undefined;

    const initialValues: CreateRoleFormValues = {
        name: role?.name || '',
        selectedRole: role?.name || '',
        description: role?.description || '',
        privilege: role?.privilege ? {
            label: privilegeOptions.find((option) => option.value === role.privilege)?.label,
            value: role.privilege,
        } : null,
        idpgroup: role?.idpgroup || '',
        selectedDeviceGroups: role?.groupIds ? role.groupIds.map((groupId: string) => ({
            label: groupId,
            value: groupId,
        })) : [],
        region: null,
        country: null,
        site: null,
    };

    if (role?.resource) {
        const locations = JSON.parse(role.resource.toString());
        const resourceRegionId = locations[0].split('/')[2].split('=')[1];
        const resourceCountryId = locations[0].split('/')[3].split('=')[1];
        const resourceSiteId = locations[0].split('/')[4].split('=')[1];

        initialValues.region = { label: undefined, value: resourceRegionId };
        initialValues.country = { label: undefined, value: resourceCountryId };
        initialValues.site = { label: undefined, value: resourceSiteId };
    };

    const { values, setFieldValue, errors, handleSubmit } = useFormik<CreateRoleFormValues>({
        initialValues,
        validate: validateCreateRoleFormValues,
        onSubmit: (values) => {
            if (!isEditMode) {
                mutate({
                    name: values.name,
                    hierarchyProviderRole: values.selectedRole,
                    description: values.description,
                    privilege: values.privilege?.value,
                    idpgroup: values.idpgroup,
                    groupIds: values.selectedDeviceGroups.map(
                        (deviceGroup) => deviceGroup.value
                    ),
                    locations: [
                        `/location/region=${values.region?.value}/country=${values.country?.value}/site=${values.site?.value}`,
                    ],
                });
            } else {
                mutate({
                    description: values.description,
                    idpgroup: values.idpgroup,
                    groupIds: values.selectedDeviceGroups.map(
                        (deviceGroup) => deviceGroup.value
                    ),
                    hierarchyProviderRole: values.selectedRole,
                })
            }
        },
    });

    const { mutate, isPending } = useMutation<UserRolesProps, CreateRoleDto>({
        api: userManagerAPI,
        method: isEditMode ? 'PATCH' : 'POST',
        url: `${API_URL_PATH_UM_ROLES}${isEditMode ? `/${role?.name}` : ''}`,
        notifications: [
            {type: 'success', content: `Successfully ${isEditMode ? 'edited' : 'created'} role ${values.name}`},
            {type: 'error'},
        ],
        onSuccess: () => navigate(URL_FULL_PATH_USER_MANAGER_ROLES)
    });

    const isSuperAdmin = values.privilege?.value === 'super_admin';

    return (
      <form onSubmit={(event) => {
            setHasSubmitted(true);
            event.preventDefault();
            handleSubmit();
      }}>
        <Form
                header={<Header variant='h2'>{isEditMode ? `Edit role ${role?.name}` : `Create a role`}</Header>}
                actions={
                  <SpaceBetween direction='horizontal' size='xs'>
                    <Button
                            formAction='none'
                            disabled={isPending}
                            variant='link'
                            onClick={() => navigate('/user-manager/roles')}
                        >
                      Cancel
                    </Button>
                    <Button variant='primary' loading={isPending}>
                      Submit
                    </Button>
                  </SpaceBetween>
                }
            >
          <SpaceBetween direction='vertical' size='l'>
            <FormField label='Role Name' errorText={hasSubmitted && errors.name}>
              <Input
                            disabled={isEditMode}
                            value={values.name}
                            onChange={(event) => setFieldValue('name', event.detail.value)}
                        />
            </FormField>
            <FormField label="Use hierarchy from role">
              <RolePicker
                            selectedRole={values.selectedRole}
                            onChangeRole={(newValue) => setFieldValue('selectedRole', newValue)}
                            defaultRole="Create new hierarchy" />
            </FormField>
            <FormField label="Description - optional" errorText={hasSubmitted && errors.description}>
              <Input
                            value={values.description}
                            onChange={(event) => setFieldValue('description', event.detail.value)}
                        />
            </FormField>
            <FormField label='Privilege' errorText={hasSubmitted && errors.privilege}>
              <Select
                            selectedOption={values.privilege}
                            onChange={({ detail }) => setFieldValue('privilege', detail.selectedOption)}
                            options={privilegeOptions}
                            disabled={isSuperAdmin || isEditMode}
                        />
            </FormField>
            <FormField label='AD Group' errorText={hasSubmitted && errors.idpgroup}>
              <Input
                            value={values.idpgroup}
                            onChange={({ detail }) => setFieldValue('idpgroup', detail.value)}
                        />
            </FormField>
            <FormField errorText={hasSubmitted && errors.selectedDeviceGroups?.toString()}>
              <DeviceGroups
                            selectedItem={values.selectedDeviceGroups}
                            setSelectedItem={(selectedDeviceGroups) => setFieldValue('selectedDeviceGroups', selectedDeviceGroups)}
                            selectionType='multi'
                            disabled={isSuperAdmin}
                        />
            </FormField>
            <Location
                        region={values.region}
                        country={values.country}
                        site={values.site}
                        regionError={errors.region}
                        countryError={errors.country}
                        siteError={errors.site}
                        setRegion={(region) => setFieldValue('region', region)}
                        setCountry={(country) => setFieldValue('country', country)}
                        setSite={(site) => setFieldValue('site', site)}
                        deepLocations={false}
                        disableFormFields={isSuperAdmin || isEditMode}
                        hasSubmitted={hasSubmitted}
                    />
          </SpaceBetween>
        </Form>
      </form>
    );
};

export default CreateRole;
