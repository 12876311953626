import { Alert, Button, Container, Header } from '@cloudscape-design/components';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useDashboardContext } from 'providers/DashboardProvider';
import DeviceCard from 'components/device-card';

const DeviceCardContainer = () => {
    const { chartDevices } = useDashboardContext();
    const queryClient = useQueryClient();
    const [isRefreshing, setIsRefreshing] = useState(false);

    const handleRefresh = async () => {
        setIsRefreshing(true);
        await queryClient.invalidateQueries({
            queryKey: ['device'],
            exact: false,
        });
        await queryClient.invalidateQueries({
            queryKey: ['device-chart'],
            exact: false,
        });
        setIsRefreshing(false);
    };

    return (
      <Container header={<Header variant='h2' actions={
        <Button onClick={handleRefresh} variant='icon' iconName='refresh' loading={isRefreshing} />
        }>
        Selected Devices
      </Header>}>
        <div style={{ display: 'flex', overflow: 'auto', gap: 10 }}>
          {chartDevices.length === 0 && <Alert>No devices selected.</Alert>}
          {chartDevices.length > 0 && chartDevices.map((device, i) => (
            <DeviceCard key={i} deviceId={device.id} deviceColor={device.color} />)
                )}
        </div>
      </Container>
    );
};

export default DeviceCardContainer;
