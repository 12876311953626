import { Badge, Spinner } from "@cloudscape-design/components";
import { TreeItem } from "@mui/x-tree-view";
import AodIcon from '@mui/icons-material/Aod';
import FolderIcon from '@mui/icons-material/Folder';

import { useDashboardContext } from "providers/DashboardProvider";
import { LocationNode } from "types/custom";

const LocationItem = ({ nodes: node, getDevicesLoading, handleNodeClick }: {
  nodes: LocationNode;
  getDevicesLoading: boolean;
  handleNodeClick: (node: LocationNode) => void;
}) => {
  const { selectedNode } = useDashboardContext();
  return (
    <TreeItem
      key={node.id}
      itemId={node.id + node.title}
      label={
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{getDevicesLoading && selectedNode === node && <Spinner/>} {node?.name || node.id}</span>
          <Badge>{node?.code}</Badge>
        </div>
      }
      slots={{ icon: node.isDevice ? AodIcon : FolderIcon }}
      onClick={() => handleNodeClick(node)}
      sx={{ "& > div": { padding: "2px" } }}
    >
      {node.children && Array.isArray(node.children)
        ? node.children.map((node) => (<LocationItem
          key={node.id + node.title}
          nodes={node}
          getDevicesLoading={getDevicesLoading}
          handleNodeClick={handleNodeClick} />
        )) : null
      }
    </TreeItem>
  )
};

export default LocationItem;