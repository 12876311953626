import { DateRangePickerProps } from "@cloudscape-design/components";

export const secondsToRelative = (seconds: number|undefined, zeroIsNull: boolean = false)
: DateRangePickerProps.RelativeValue | null => {
  if (seconds === undefined) seconds = 0;
  if (seconds === -1) return null;
  if (zeroIsNull && seconds === 0) return null;
  if (seconds < 60) return intervalToRelative(seconds, 'second');
  if (seconds < 3600) return intervalToRelative(seconds / 60, 'minute');
  if (seconds < 86400) return intervalToRelative(seconds / 3600, 'hour');
  if (seconds < 604800) return intervalToRelative(seconds / 86400, 'day');
  if (seconds < 2628000) return intervalToRelative(seconds / 604800, 'week');
  if (seconds < 31536000) return intervalToRelative(seconds / 2628000, 'month');
  return intervalToRelative(seconds / 31536000, 'year');
}

const intervalToRelative = (amount: number, unit: DateRangePickerProps.TimeUnit): DateRangePickerProps.RelativeValue | null => ({ key: `${amount}-${unit}`, type: 'relative', amount, unit });

export const relativeToText = (relative: DateRangePickerProps.RelativeValue | null, disabled: string = 'Disabled', notExists: string = 'Not exists') => {
  if (!relative) return notExists;
  if (!relative.amount) return disabled;
  return `${relative.amount} ${relative.amount === 1 ? relative.unit : relative.unit + 's'}`;
}

export const relativeToSeconds = (relative: DateRangePickerProps.RelativeValue | null, defaultIfNull: number = -1) => {
  if (relative === null) return defaultIfNull;
  const { amount, unit } = relative;
  switch (unit) {
  case 'second': return amount;
  case 'minute': return amount * 60;
  case 'hour': return amount * 3600;
  case 'day': return amount * 86400;
  case 'week': return amount * 604800;
  case 'month': return amount * 2628000;
  case 'year': return amount * 31536000;
  default: return -1;
  }
}