import { Tabs } from '@cloudscape-design/components';

import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

import Contacts from '../Contacts';
import EscalationPlans from '../EscalationPlans';
import NotificationGroups from '../NotificationGroups';

const NotificationManagerMainTabs = () => {
    const { activeMainTabId, setActiveMainTabId } =
        useNotificationManagerContext();

    return (
      <Tabs
            tabs={[
                {
                    label: 'Contacts',
                    id: 'contacts',
                    content: <Contacts />,
                },
                {
                    label: 'Notification Groups',
                    id: 'notification-groups',
                    content: <NotificationGroups />,
                },
                {
                    label: 'Escalation Plans',
                    id: 'escalation-plans',
                    content: <EscalationPlans />,
                },
            ]}
            activeTabId={activeMainTabId}
            onChange={({ detail }) => setActiveMainTabId(detail.activeTabId)}
        />
    );
};

export default NotificationManagerMainTabs;
