import { useState } from 'react';
import { Grid, Container, Header, Button, SpaceBetween } from '@cloudscape-design/components';

import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';

import ContactsList from './ContactsList';
import CreateUpdateContact from './CreateUpdateContact';

const Contacts = () => {
  const [isUpdatingContact, setIsUpdatingContact] = useState(false);

  const { contactsFetching, contactsRefetch } = useNotificationManagerContext();

  return (
    <Grid
      gridDefinition={[
        { colspan: { l: 6, m: 6, s: 6, xs: 12, xxs: 12 } },
        { colspan: { l: 6, m: 6, s: 6, xs: 12, xxs: 12 } },
      ]}
    >
      <Container
        className='custom-height-container custom-scroll-container'
        header={
          <Header variant='h3' actions={<SpaceBetween size='xs' direction='horizontal'>
            <Button onClick={() => setIsUpdatingContact(false)} variant='normal' disabled={contactsFetching}>Create</Button>
            <Button iconName='refresh' onClick={contactsRefetch} loading={contactsFetching} />
          </SpaceBetween>}>
            Contacts
          </Header>
        }>
        <ContactsList setIsUpdatingContact={setIsUpdatingContact} />
      </Container>


      <Container className='custom-height-container custom-scroll-container'>
        <CreateUpdateContact isUpdating={isUpdatingContact} setIsUpdatingContact={setIsUpdatingContact} />
      </Container>
    </Grid>
  );
};

export default Contacts;
