import { ContentLayout, Grid, Header } from '@cloudscape-design/components';
import { Outlet } from 'react-router-dom';

import colors from '../../theme/colors';

const NotificationManager = () => {
    return (
      <ContentLayout defaultPadding headerBackgroundStyle={colors.accentGreen2} header={<Header variant="h1">Notification Manager</Header>}>
        <Grid gridDefinition={[{ colspan: 12 }]}>
          <Outlet />
        </Grid>
      </ContentLayout>
    );
};

export default NotificationManager;
