import { useEffect, useState } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween, Spinner } from '@cloudscape-design/components';

import { incidentManagerAPI } from 'api';
import { API_URL_PATH_IM_CONTACTS } from 'constants/urls';
import ListItemComp from 'components/incident-manager/ListItem';
import { useNotificationManagerContext } from 'pages/notification-manager/NotificationManagerPage';
import useMutation from 'hooks/useMutation';

type ContactListProps = {
    setIsUpdatingContact: (state: boolean) => void;
};

const ContactsList = ({ setIsUpdatingContact }: ContactListProps) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [contactToDelete, setContactToDelete] = useState<any>();
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const {
        allContacts,
        setSelectedContact,
        contactsRefetch,
        contactsLoading
    } = useNotificationManagerContext();

    const {
        mutateAsync: updateContact,
        isPending: updateContactLoading,
    } = useMutation<{}, {
        active: boolean;
    }>({
        api: incidentManagerAPI,
        method: 'PATCH',
        notifications: [
            {type: 'success', content: 'Contact updated successfully'},
            {type: 'error', content: 'Error updating contact'}
        ],
        onSuccess: () => contactsRefetch(),
        onError: () => contactsRefetch()
    });

    const {
        mutateAsync: deleteContact,
        isPending: deleteContactLoading,
    } = useMutation<string, {}>({
        api: incidentManagerAPI,
        method: 'DELETE',
        notifications: [
            {type: 'success', content: 'Contact deleted successfully'},
            {type: 'error', content: 'Error deleting contact'}
        ]
    });

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
        setIsUpdatingContact(true);
    };

    const handleDisableContact = (event: React.ChangeEvent<HTMLInputElement>, contact: any) => {
        updateContact({
            fullUrl: `${API_URL_PATH_IM_CONTACTS}/${contact.alias}`,
            active: !contact.active,
        });
    };

    const showDeleteModal = (contact: any) => {
        setContactToDelete(contact);
        setDeleteModalVisible(true);
    };

    useEffect(() => {
        if (allContacts) {
            setSelectedContact(allContacts[0]);
            setSelectedIndex(0);
        }
    }, [allContacts]);

    return (
      <>
        {contactsLoading && <Spinner />}
        {(updateContactLoading || deleteContactLoading) && <Spinner />}
        {allContacts && allContacts.length === 0 && (
          <Alert>No contacts found</Alert>
            )}

        <Modal
                onDismiss={() => setDeleteModalVisible(false)}
                header='Delete Contact'
                visible={deleteModalVisible || deleteContactLoading}
                closeAriaLabel='Close modal'
                footer={
                  <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                      <Button
                                variant='link'
                                onClick={() => setDeleteModalVisible(false)}
                                disabled={deleteContactLoading}>
                        Cancel
                      </Button>
                      <Button
                        variant='primary'
                        onClick={async () => {
                            await deleteContact({ fullUrl: `${API_URL_PATH_IM_CONTACTS}/${contactToDelete?.alias}` })
                            setDeleteModalVisible(false);
                            contactsRefetch();
                        }}
                        loading={deleteContactLoading}>
                        Delete
                      </Button>
                    </SpaceBetween>
                  </Box>
                }
            >
          <div>
            Are you sure you want to delete {" " + contactToDelete?.alias} permanently?
            <i>You can't undo this action.</i>
          </div>
        </Modal>

        <ListItemComp
                allItems={allContacts}
                selectedIndex={selectedIndex}
                handleListItemClick={handleListItemClick}
                setSelectedItem={setSelectedContact}
                module='contacts'
                disableContact={handleDisableContact}
                isLoading={updateContactLoading || deleteContactLoading}
                removeContact={showDeleteModal}
            />
      </>
    );
};

export default ContactsList;
